/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Generated using the Bootstrap Customizer (http://getbootstrap.com/customize/?id=37684644372902ebd5b7)
 * Config saved to config.json and https://gist.github.com/37684644372902ebd5b7
 */
/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
}

.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-default.disabled,
.btn-primary.disabled,
.btn-success.disabled,
.btn-info.disabled,
.btn-warning.disabled,
.btn-danger.disabled,
.btn-default[disabled],
.btn-primary[disabled],
.btn-success[disabled],
.btn-info[disabled],
.btn-warning[disabled],
.btn-danger[disabled] {
  box-shadow: none;
}

fieldset[disabled] {
  .btn-default,
  .btn-primary,
  .btn-success,
  .btn-info,
  .btn-warning,
  .btn-danger {
    box-shadow: none;
  }
}

.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
  text-shadow: none;
}

.btn {
  &:active,
  &.active {
    background-image: none;
  }
}

.btn-default {
  border-color: #dbdbdb;
  border-color: #ccc;
  background-image: linear-gradient(to bottom, #fff 0%, #e0e0e0 100%);
  background-repeat: repeat-x;
  text-shadow: 0 1px 0 #fff;
  &:hover,
  &:focus {
    background-color: #e0e0e0;
    background-position: 0 -15px;
  }
  &:active,
  &.active {
    border-color: #dbdbdb;
    background-color: #e0e0e0;
  }
  &.disabled,
  &[disabled] {
    background-color: #e0e0e0;
    background-image: none;
  }
}

fieldset[disabled] .btn-default {
  background-color: #e0e0e0;
  background-image: none;
}

.btn-default {
  &.disabled:hover,
  &[disabled]:hover {
    background-color: #e0e0e0;
    background-image: none;
  }
}

fieldset[disabled] .btn-default:hover {
  background-color: #e0e0e0;
  background-image: none;
}

.btn-default {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #e0e0e0;
    background-image: none;
  }
}

fieldset[disabled] .btn-default:focus {
  background-color: #e0e0e0;
  background-image: none;
}

.btn-default {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #e0e0e0;
    background-image: none;
  }
}

fieldset[disabled] .btn-default.focus {
  background-color: #e0e0e0;
  background-image: none;
}

.btn-default {
  &.disabled:active,
  &[disabled]:active {
    background-color: #e0e0e0;
    background-image: none;
  }
}

fieldset[disabled] .btn-default:active {
  background-color: #e0e0e0;
  background-image: none;
}

.btn-default {
  &.disabled.active,
  &[disabled].active {
    background-color: #e0e0e0;
    background-image: none;
  }
}

fieldset[disabled] .btn-default.active {
  background-color: #e0e0e0;
  background-image: none;
}

.btn-primary {
  border-color: #245580;
  background-image: linear-gradient(to bottom, #337ab7 0%, #265a88 100%);
  background-repeat: repeat-x;
  &:hover,
  &:focus {
    background-color: #265a88;
    background-position: 0 -15px;
  }
  &:active,
  &.active {
    border-color: #245580;
    background-color: #265a88;
  }
  &.disabled,
  &[disabled] {
    background-color: #265a88;
    background-image: none;
  }
}

fieldset[disabled] .btn-primary {
  background-color: #265a88;
  background-image: none;
}

.btn-primary {
  &.disabled:hover,
  &[disabled]:hover {
    background-color: #265a88;
    background-image: none;
  }
}

fieldset[disabled] .btn-primary:hover {
  background-color: #265a88;
  background-image: none;
}

.btn-primary {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #265a88;
    background-image: none;
  }
}

fieldset[disabled] .btn-primary:focus {
  background-color: #265a88;
  background-image: none;
}

.btn-primary {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #265a88;
    background-image: none;
  }
}

fieldset[disabled] .btn-primary.focus {
  background-color: #265a88;
  background-image: none;
}

.btn-primary {
  &.disabled:active,
  &[disabled]:active {
    background-color: #265a88;
    background-image: none;
  }
}

fieldset[disabled] .btn-primary:active {
  background-color: #265a88;
  background-image: none;
}

.btn-primary {
  &.disabled.active,
  &[disabled].active {
    background-color: #265a88;
    background-image: none;
  }
}

fieldset[disabled] .btn-primary.active {
  background-color: #265a88;
  background-image: none;
}

.btn-success {
  border-color: #3e8f3e;
  background-image: linear-gradient(to bottom, #5cb85c 0%, #419641 100%);
  background-repeat: repeat-x;
  &:hover,
  &:focus {
    background-color: #419641;
    background-position: 0 -15px;
  }
  &:active,
  &.active {
    border-color: #3e8f3e;
    background-color: #419641;
  }
  &.disabled,
  &[disabled] {
    background-color: #419641;
    background-image: none;
  }
}

fieldset[disabled] .btn-success {
  background-color: #419641;
  background-image: none;
}

.btn-success {
  &.disabled:hover,
  &[disabled]:hover {
    background-color: #419641;
    background-image: none;
  }
}

fieldset[disabled] .btn-success:hover {
  background-color: #419641;
  background-image: none;
}

.btn-success {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #419641;
    background-image: none;
  }
}

fieldset[disabled] .btn-success:focus {
  background-color: #419641;
  background-image: none;
}

.btn-success {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #419641;
    background-image: none;
  }
}

fieldset[disabled] .btn-success.focus {
  background-color: #419641;
  background-image: none;
}

.btn-success {
  &.disabled:active,
  &[disabled]:active {
    background-color: #419641;
    background-image: none;
  }
}

fieldset[disabled] .btn-success:active {
  background-color: #419641;
  background-image: none;
}

.btn-success {
  &.disabled.active,
  &[disabled].active {
    background-color: #419641;
    background-image: none;
  }
}

fieldset[disabled] .btn-success.active {
  background-color: #419641;
  background-image: none;
}

.btn-info {
  border-color: #28a4c9;
  background-image: linear-gradient(to bottom, #5bc0de 0%, #2aabd2 100%);
  background-repeat: repeat-x;
  &:hover,
  &:focus {
    background-color: #2aabd2;
    background-position: 0 -15px;
  }
  &:active,
  &.active {
    border-color: #28a4c9;
    background-color: #2aabd2;
  }
  &.disabled,
  &[disabled] {
    background-color: #2aabd2;
    background-image: none;
  }
}

fieldset[disabled] .btn-info {
  background-color: #2aabd2;
  background-image: none;
}

.btn-info {
  &.disabled:hover,
  &[disabled]:hover {
    background-color: #2aabd2;
    background-image: none;
  }
}

fieldset[disabled] .btn-info:hover {
  background-color: #2aabd2;
  background-image: none;
}

.btn-info {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #2aabd2;
    background-image: none;
  }
}

fieldset[disabled] .btn-info:focus {
  background-color: #2aabd2;
  background-image: none;
}

.btn-info {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #2aabd2;
    background-image: none;
  }
}

fieldset[disabled] .btn-info.focus {
  background-color: #2aabd2;
  background-image: none;
}

.btn-info {
  &.disabled:active,
  &[disabled]:active {
    background-color: #2aabd2;
    background-image: none;
  }
}

fieldset[disabled] .btn-info:active {
  background-color: #2aabd2;
  background-image: none;
}

.btn-info {
  &.disabled.active,
  &[disabled].active {
    background-color: #2aabd2;
    background-image: none;
  }
}

fieldset[disabled] .btn-info.active {
  background-color: #2aabd2;
  background-image: none;
}

.btn-warning {
  border-color: #e38d13;
  background-image: linear-gradient(to bottom, #f0ad4e 0%, #eb9316 100%);
  background-repeat: repeat-x;
  &:hover,
  &:focus {
    background-color: #eb9316;
    background-position: 0 -15px;
  }
  &:active,
  &.active {
    border-color: #e38d13;
    background-color: #eb9316;
  }
  &.disabled,
  &[disabled] {
    background-color: #eb9316;
    background-image: none;
  }
}

fieldset[disabled] .btn-warning {
  background-color: #eb9316;
  background-image: none;
}

.btn-warning {
  &.disabled:hover,
  &[disabled]:hover {
    background-color: #eb9316;
    background-image: none;
  }
}

fieldset[disabled] .btn-warning:hover {
  background-color: #eb9316;
  background-image: none;
}

.btn-warning {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #eb9316;
    background-image: none;
  }
}

fieldset[disabled] .btn-warning:focus {
  background-color: #eb9316;
  background-image: none;
}

.btn-warning {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #eb9316;
    background-image: none;
  }
}

fieldset[disabled] .btn-warning.focus {
  background-color: #eb9316;
  background-image: none;
}

.btn-warning {
  &.disabled:active,
  &[disabled]:active {
    background-color: #eb9316;
    background-image: none;
  }
}

fieldset[disabled] .btn-warning:active {
  background-color: #eb9316;
  background-image: none;
}

.btn-warning {
  &.disabled.active,
  &[disabled].active {
    background-color: #eb9316;
    background-image: none;
  }
}

fieldset[disabled] .btn-warning.active {
  background-color: #eb9316;
  background-image: none;
}

.btn-danger {
  border-color: #b92c28;
  background-image: linear-gradient(to bottom, #d9534f 0%, #c12e2a 100%);
  background-repeat: repeat-x;
  &:hover,
  &:focus {
    background-color: #c12e2a;
    background-position: 0 -15px;
  }
  &:active,
  &.active {
    border-color: #b92c28;
    background-color: #c12e2a;
  }
  &.disabled,
  &[disabled] {
    background-color: #c12e2a;
    background-image: none;
  }
}

fieldset[disabled] .btn-danger {
  background-color: #c12e2a;
  background-image: none;
}

.btn-danger {
  &.disabled:hover,
  &[disabled]:hover {
    background-color: #c12e2a;
    background-image: none;
  }
}

fieldset[disabled] .btn-danger:hover {
  background-color: #c12e2a;
  background-image: none;
}

.btn-danger {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #c12e2a;
    background-image: none;
  }
}

fieldset[disabled] .btn-danger:focus {
  background-color: #c12e2a;
  background-image: none;
}

.btn-danger {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #c12e2a;
    background-image: none;
  }
}

fieldset[disabled] .btn-danger.focus {
  background-color: #c12e2a;
  background-image: none;
}

.btn-danger {
  &.disabled:active,
  &[disabled]:active {
    background-color: #c12e2a;
    background-image: none;
  }
}

fieldset[disabled] .btn-danger:active {
  background-color: #c12e2a;
  background-image: none;
}

.btn-danger {
  &.disabled.active,
  &[disabled].active {
    background-color: #c12e2a;
    background-image: none;
  }
}

fieldset[disabled] .btn-danger.active {
  background-color: #c12e2a;
  background-image: none;
}

.thumbnail,
.img-thumbnail {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
}

.dropdown-menu > {
  li > a {
    &:hover,
    &:focus {
      background-color: #e8e8e8;
      background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
      background-repeat: repeat-x;
    }
  }
  .active > a {
    background-color: #2e6da4;
    background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
    background-repeat: repeat-x;
    &:hover,
    &:focus {
      background-color: #2e6da4;
      background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
      background-repeat: repeat-x;
    }
  }
}

.navbar-default {
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #fff 0%, #f8f8f8 100%);
  background-repeat: repeat-x;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
  .navbar-nav > {
    .open > a,
    .active > a {
      background-image: linear-gradient(to bottom, #dbdbdb 0%, #e2e2e2 100%);
      background-repeat: repeat-x;
      box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
    }
  }
}

.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255, 255, 255, .25);
}

.navbar-inverse {
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #3c3c3c 0%, #222 100%);
  background-repeat: repeat-x;
  .navbar-nav > {
    .open > a,
    .active > a {
      background-image: linear-gradient(to bottom, #080808 0%, #0f0f0f 100%);
      background-repeat: repeat-x;
      box-shadow: inset 0 3px 9px rgba(0, 0, 0, .25);
    }
  }
  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
  }
}

.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

@media (max-width: 767px) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    color: #fff;
    background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
    background-repeat: repeat-x;
    &:hover,
    &:focus {
      color: #fff;
      background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
      background-repeat: repeat-x;
    }
  }
}

.alert {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .25), 0 1px 2px rgba(0, 0, 0, .05);
  text-shadow: 0 1px 0 rgba(255, 255, 255, .2);
}

.alert-success {
  border-color: #b2dba1;
  background-image: linear-gradient(to bottom, #dff0d8 0%, #c8e5bc 100%);
  background-repeat: repeat-x;
}

.alert-info {
  border-color: #9acfea;
  background-image: linear-gradient(to bottom, #d9edf7 0%, #b9def0 100%);
  background-repeat: repeat-x;
}

.alert-warning {
  border-color: #f5e79e;
  background-image: linear-gradient(to bottom, #fcf8e3 0%, #f8efc0 100%);
  background-repeat: repeat-x;
}

.alert-danger {
  border-color: #dca7a7;
  background-image: linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);
  background-repeat: repeat-x;
}

.progress {
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
}

.progress-bar {
  background-image: linear-gradient(to bottom, #337ab7 0%, #286090 100%);
  background-repeat: repeat-x;
}

.progress-bar-success {
  background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  background-repeat: repeat-x;
}

.progress-bar-info {
  background-image: linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  background-repeat: repeat-x;
}

.progress-bar-warning {
  background-image: linear-gradient(to bottom, #f0ad4e 0%, #ec971f 100%);
  background-repeat: repeat-x;
}

.progress-bar-danger {
  background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  background-repeat: repeat-x;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.list-group {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
}

.list-group-item.active {
  border-color: #2b669a;
  background-image: linear-gradient(to bottom, #337ab7 0%, #2b669a 100%);
  background-repeat: repeat-x;
  text-shadow: 0 -1px 0 #286090;
  &:hover,
  &:focus {
    border-color: #2b669a;
    background-image: linear-gradient(to bottom, #337ab7 0%, #2b669a 100%);
    background-repeat: repeat-x;
    text-shadow: 0 -1px 0 #286090;
  }
  .badge,
  &:hover .badge,
  &:focus .badge {
    text-shadow: none;
  }
}

.panel {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

.panel-default > .panel-heading {
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  background-repeat: repeat-x;
}

.panel-primary > .panel-heading {
  background-image: linear-gradient(to bottom, #337ab7 0%, #2e6da4 100%);
  background-repeat: repeat-x;
}

.panel-success > .panel-heading {
  background-image: linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
  background-repeat: repeat-x;
}

.panel-info > .panel-heading {
  background-image: linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
  background-repeat: repeat-x;
}

.panel-warning > .panel-heading {
  background-image: linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
  background-repeat: repeat-x;
}

.panel-danger > .panel-heading {
  background-image: linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
  background-repeat: repeat-x;
}

.well {
  border-color: #dcdcdc;
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05), 0 1px 0 rgba(255, 255, 255, .1);
}
