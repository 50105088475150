@import "import/variables";
@import 'import/bootstrap';
@import 'import/bootstrap-theme';
@import 'import/owl.carousel';
@import 'import/owl.theme.default';
@import 'import/wordpress';
@import 'import/slider-pro.min';
@import 'aos';
body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: $base-line-height;
  min-width: 320px;
  color: $base-font-color;
  background-color: $base-background-color;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: 0;
}
p,
pre {
  margin: 0;
}
a {
  text-decoration: none;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}
ul {
  padding: 0;
  margin: 0;
}
img,
picture {
  max-width: 100%;
  margin: 0;
}
.wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;

  @extend .clearfix;
}
.container {
  position: relative;
}

.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.transition {
  transition: .3s all ease-out;
}

.language {
  background-color: #000;
  padding: 5px 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 80;
  @extend .transition;
  .lang__inner {
    @extend .flex;
    align-items: center;
    justify-content: flex-end;
    .language-chooser {
      @extend .flex;
      align-items: center;
      li {
          margin: 0 4px;
          padding: 0 2px;
          list-style-type: none;
          &:hover {
            a {
              color: #00aae6;
            }
          }
        a {
          font-size: 14px;
          font-weight: 300;
          color: #e0e0e0;
          text-transform: lowercase;
          @extend .transition;
        }
        &.active {
            border: thin solid #aaaaaa;
            &:hover {
            a {
              color: #e0e0e0;
            }
          }
        }
      }
    }
    svg {
      width: 15px;
      height: 15px;
      margin-left: 4px;
      margin-top: 4px;
      path {
        fill: #00aae6;
      }
    }
  }
}

.header__top {
  top: 0;
}
header {
  background-color: #4d4f8c;
  color: #fff;
  padding: 25px 0;
  @extend .transition;
  position: fixed;
  left: 0;
  top: 32.2px;
  width: 100%;
  z-index: 99;
  .mobile__menu {
    display: none;
  }
  .mobile__bar {
    display: none;
  }
  &.header__hover {
    background-color: #322c74;
    &:after {
      display: block;
      content:'';
      width: 100%;
      height: 1px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 99;
    }
    .sub__menu--banner {
      opacity: 1;
      visibility: visible;
    }
  }
  .sub__menu--banner {
    position: absolute;
    top: 98px;
    left: 0;
    background-color: #000;
    padding: 30px 0 30px 340px;
    width: calc(100% - 365px);
    @extend .flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    @extend .transition;
    .text__wrap {
      margin-left: 45px;
      h6 {
        font-size: 18px;
        margin-bottom: 10px;
      }
      p {
        font-weight: 300;
        opacity: .8;
      }
    }
  }
  .col-md-12 {
    @extend .flex;
  }
  .header--logo {
    display: block;
    margin-right: 250px;
    width: 100%;
    max-width: 175px;
    img, svg {
      max-width: 100%;
      width: 100%;
      max-width: 175px;
    }
  }
}
nav {
  padding-top: 15px;
  ul {
    @extend .clearfix;
    li {
      display: inline-block;
      margin-right: 70px;
      position: relative;
      &:after {
        display: block;
        content:'';
        width: 100%;
        height: 1px;
        background-color: #fff;
        margin-top: 15px;
        opacity: 0;
        @extend .transition;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        color: #fff;
        font-size: 18px;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
        a {
          color: #00aae6;
        }
      }
      .sub-menu {
        position: absolute;
        left: -120px;
        padding: 50px;
        padding-top: 70px;
        margin-top: 20px;
        width: 340px;
        opacity: 0;
        visibility: hidden;
        z-index: 10;
        @extend .transition;
        background-color: #322c74;
        li {
          @extend .flex;
          margin-bottom: 25px;
          margin-right: 0;
          &:last-child {
            margin-bottom: 0;
          }
          &:after {
            display: none;
           }
          a {
            @extend .transition;
            font-size: 16px;
            &:after {
              display: block;
              content:'';
              width: 100%;
              height: 1px;
              background-color: #6cb7df;
              margin-top: 1px;
              opacity: 0;
              @extend .transition;
            }
          }
          &:hover {
            a {
              color: #6cb7df;
              text-decoration: none;
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .current-menu-item {
      &:after {
        opacity: 1;
      }
    }
    .menu-item-has-children {
      &:before {
        display: block;
        content:'';
        width: 0; 
        height: 0; 
        border-left: 3.5px solid transparent;
        border-right: 3.5px solid transparent;
        border-top: 4px solid #fff;
        position: absolute;
        top: 7px;
        right: -17px;
        @extend .transition;
      }
      &:hover {
        &:before {
          transform: rotate(-180deg);
        }
        .sub-menu {
          opacity: 1;
          visibility: visible;
          li {
            a {
              color: #fff;
              @extend .transition;
              &:hover {
                color: #6cb7df;
              }
            }
          }
        }
      }
    }
  }
}

//Block title style
.block__number {
  font-size: 48px;
  opacity: .1;
  text-align: center;
  width: 100%;
}

.block__title {
  font-size: 54px;
  font-weight: 400;
  text-align: center;
}

.block__line {
  width: 280px;
  height: 1px;
  margin: 0 auto;
  background-color: #51568b;
  position: relative;
  text-align: center;
  margin-bottom: 25px;
  /*&:before {
    display: block;
    content:'';
    height: 1px;
    width: 120px;
    position: absolute;
    left: 0;
    background-color: #51568b;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  &:after {
    display: block;
    content:'';
    height: 1px;
    width: 120px;
    position: absolute;
    right: 0;
    background-color: #51568b;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  */
  span {
    display: inline-block;
    width: 13px;
    height: 17px;
    position: relative;
    display: none;
    &:before {
      display: block;
      content:'';
      border-left: 6.5px solid transparent;
      border-right: 6.5px solid transparent;
      border-bottom: 8px solid #a4a6c1;
    }
    &:after {
      display: block;
      content:'';
      border-left: 6.5px solid transparent;
      border-right: 6.5px solid transparent;
      border-top: 8px solid #4d4f8c;
      margin-top: 1px;
    }
  }
}

//Main page
section {
  position: relative;
  overflow: hidden;
  &:after {
    display: block;
    content:'';
    width: 700px;
    height: 620px;
    position: absolute;
    right: -360px;
    top: 1130px;
    background:transparent url('../img/logo-p.svg') center no-repeat;
    background-size: cover;
  }
}

.first__block {
  height: calc(100vh - 132px);
  overflow: hidden;
  position: relative;
  margin-top: 130px;
  .slider__item {
    position: relative;
    height: calc(100vh - 132px);
      &:before {
      display: block;
      content:'';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(50, 44, 116, 0.6);
      z-index: 2;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .slider__text--wrap {
      position: absolute;
      top: 220px;
      left: 325px;
      z-index: 3;
      color: #fff;
      .mark {
        font-weight: 300;
        display: block;
        margin-bottom: 30px;
        text-transform: uppercase;
      }
      h4 {
        font-size: 64px;
        font-weight: 500;
        margin-bottom: 30px;
        line-height: 1.3;
      }
      .slider__undertitle {
        max-width: 520px;
        line-height: 1.5;
        margin-bottom: 45px;
        span {
          font-weight: 500;
        }
      }
      .button__wrap {
        @extend .flex;
        a {
          font-size: 14px;
          color: #fff;
          padding: 18px 0 17px 0;
          width: 180px;
          display: inline-block;
          background-color: #51568b;
          text-align: center;
          margin-right: 4px;
          max-height: 50px;
          @extend .transition;
          &:hover {
            background-color: transparent;
            outline: 1px solid #51568b;
          }
        }
        .video__open {
          width: 50px;
          height: 50px;
          background-color: #fff;
          @extend .flex;
          position: relative;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          @extend .transition;
          &:before {
            display: block;
            content:'';
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 7px solid #51568b;
            @extend .transition;       
          }
          &:hover {
            background-color: #51568b;
            &:before {
              border-left-color: #fff;
            }
          }
        }
      }
    }
  }
  #banner__owl {
    height: 100%;
    .owl-dots {
      display: none;
    }
    .owl-nav {
      display: none;
        button {
          width: 50px;
          height: 50px;
          border: 1px solid #fff;
          @extend .flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          position: absolute;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          z-index: 8;
          @extend .transition;
          &:focus {
            outline: none;
          }
          &:hover {
            background-color: #fff;
            span {
              border-color: #85c3e5;
            }
          }
          span {
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
            width: 12px;
            height: 12px;
            @extend .transition; 
          }
        }
        .owl-prev {
          left: 130px;
          span {
            transform: rotate(45deg);
            margin-left: 5px;
          }
        }
        .owl-next {
          right: 130px;
          span {
            transform: rotate(-135deg);
            margin-right: 7px;
          }
        }
      }
  }
  .to__second {
    width: 50px;
    height: 60px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    z-index: 10;
    @extend .transition;
    @extend .flex;
    align-items: center;
    justify-content: center;
    &:before {
      display: block;
      content:'';
      height: 15px;
      width: 15px;
      background: transparent url('../img/double-arrow-down.png') center no-repeat;
      background-size: contain;
      position: absolute;
      animation-name: down;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    &:hover {
    }
  }
}


//To second arrow animation
@keyframes down {
  0%   {
    transform: translateY(-30px);
    opacity: 0;
    transition: opacity 1200ms ease 0s, transform 800ms ease 0s;
  }
  50%  {
    transform: translateY(-25px);
    opacity: 1;
    transition: opacity 400ms ease 0s, transform 800ms ease 0s;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
    transition: opacity 1200ms ease 0s, transform 800ms ease 0s;
  }
}

.second__block {
  padding-top: 80px;
  margin-bottom: 30px;
  position: relative;
  @extend .flex;
  overflow: hidden;
  .left__side--image {
    margin-right: 30px;
    display: inline-block;
    max-width: 900px;
    width: 50%;
  }
  .right__side--content {
    display: inline-block;
    width: 50%;
    max-width: 630px;
    padding-top: 90px;
    padding-right: 15px;
    .block__number {
      text-align: left;
      margin-bottom: 25px;
    }
    .right__block--title {
      width: 130px;
      position: relative;
      margin-bottom: 40px;
      h4 {
        display: inline-block;
        font-weight: 300;
        text-transform: uppercase;
        margin-right: 8px;
        position: relative;
      }
      .triangles__title {
        display: inline-block;
        &:before {
          display: block;
          content:'';
          border-left: 6.5px solid transparent;
          border-right: 6.5px solid transparent;
          border-bottom: 8px solid #a4a6c1;
        }
        &:after {
          display: block;
          content:'';
          border-left: 6.5px solid transparent;
          border-right: 6.5px solid transparent;
          border-top: 8px solid #4d4f8c;
          margin-top: 1px;
        }
      }
    }
    .content__titile {
      font-size: 54px;
      font-weight: 400;
      margin-bottom: 40px;
    }
    .about__undertitle {
      font-family: $second-font-family;
      font-style: italic;
      font-size: 14px;
      margin-bottom: 40px;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 50px;
      span {
        font-weight: 600;
      }
    }
    a {
      display: block;
      padding: 17px 0 16px 0;
      width: 200px;
      background-color: #51568b;
      color: #fff;
      text-align: center;
      margin-bottom: 2px;
      @extend .transition;
      &:hover {
        outline: 1px solid #51568b;
        background-color: transparent;
        color: #51568b;
      }
    }
  }
}

.third__block {
  padding-top: 290px;
  position: relative;
  &:before {
    display: block;
    content:'';
    width: 110%;
    height: 100%;
    background:linear-gradient(to bottom, #f6f6f7, #f7f7f8);
    position: absolute;
    top: 170px;
    left: -50px;
    transform: rotate(-3deg);
  }
  .block__number {
    margin-bottom: 25px;
  }
  .block__title {
    margin-bottom: 115px;
  }
  .owl-item {
    height: 425px;
    padding-top: 15px;
  }
  .slider__item {
    height: 100%;
    a {
      display: block;
      position: relative;
      z-index: 5;
    }
    .image__wrap {
      position: relative;
      height: 285px;
      margin-bottom: 20px;
      @extend .transition;
      @extend .flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      .show__product--link {
        text-align: center;
        opacity: 0;
        visibility: hidden;
        @extend .transition;
        font-size: 12px;
        font-weight: 300;
        color: #fff;
        text-decoration: underline;
      }
    }
    img {
      max-height: 260px;
      width: auto;
      margin: 0 auto;
      margin-bottom: 10px;
      position: relative;
      @extend .transition;
      object-fit: cover;
    }
    .bottom__bg {
      height: 150px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #51568b url('../img/logo-p.svg') no-repeat 130% 100%;
      @extend .transition;
      @extend .flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 35px;
    }
    &:hover {
      .image__wrap {
          transform: translateY(-15px);
          img {
            transform: scale(1.1);
          }
          .show__product--link {
            opacity: 1;
            visibility: visible;
          }
      }
      .bottom__bg {
        background: #6cb7df url('../img/logo-p.svg') no-repeat 130% 100%;
        box-shadow: 0 20px 15px 0 rgba(108, 183, 223, 0.5);
      }
      .description__block {
        transform: translateY(-15px);
      }
    }
    .description__block {
      text-align: center;
      padding-top: 35px;
      position: relative;
      @extend .transition;
      &:before {
        display: block;
        content:'';
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        height: 20px;
        width: 1px;
        background-color: #8c8c8c;
      }
      h4 {
        font-size: 18px;
        margin-bottom: 10px;
      }
      p {
        font-weight: 300;
      }
    }
  }
  #products__owl {
    margin-bottom: 60px;
    .owl-dots {
      margin: 50px 0 180px 0;
      text-align: center;
      button {
        width: 10px;
        height: 10px;
        border: 2px solid #6cb7df;
        border-radius: 50%;
        margin-right: 8px;
        background-color: transparent;
        @extend .transition;
        transition-delay: .3s;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background-color: #51568b;
          border-color: #51568b;
        }
        &:focus {
          outline: none;
        }
        &.active {
          background-color: #51568b;
          border-color: #51568b;
        }
      }
    }
    .owl-nav {
        button {
          width: 50px;
          height: 50px;
          border: 1px solid #51568b;
          @extend .flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          position: absolute;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          z-index: 8;
          @extend .transition;
          &:focus {
            outline: none;
          }
          &:hover {
            background-color: #51568b;
            span {
              border-color: #85c3e5;
            }
          }
          span {
            display: none;
          }
        }
        .owl-prev {
          left: -110px;
          &:before {
            display: block;
            content:'';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background:transparent url('../img/back.svg') center no-repeat;
            background-size: 20px;
          }
        }
        .owl-next {
          right: -110px;
          &:before {
            display: block;
            content:'';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background:transparent url('../img/back.svg') center no-repeat;
            background-size: 20px;
            transform: rotate(180deg);
          }
        }
      }
  }
}

.fourth__block {
  position: relative;
  padding-top: 165px;
  padding-bottom: 140px;
  &:before {
    display: block;
    content:'';
    width: 110%;
    height: 100%;
    background: #edeff7;
    position: absolute;
    top: 30px;
    left: -50px;
    transform: rotate(-3deg);
  }
  .block__number {
    margin-bottom: 25px;
  }
  .block__line {
    margin-bottom: 25px;
  }
  .block__title {
    margin-bottom: 150px;
  }
  .advantages__wrap {
    @extend .flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .advantage__item {
    width: 24%;
    max-width: 360px;
    padding: 40px;
    position: relative;
    cursor: pointer;
    margin-right: 30px;
    margin-bottom: 30px;
    @extend .transition;
    &:hover {
      box-shadow: 0 20px 87px 0 rgba(50, 44, 116, 0.5);
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:before {
      display: block;
      content:'';
      width: 100%;
      height: 100%;
      background-color: rgba(50, 44, 116, 0.7);
      position: absolute;
      left: 0;
      bottom: 0;
      @extend .transition;
      z-index: 5;
    }
    &:after {
      display: block;
      content:'';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: transparent url(../img/logo-p.svg) no-repeat 120% 100%;
      background-size: 150px;
      z-index: 20;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .number {
      font-size: 72px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 15px;
      position: relative;
      z-index: 10;
      text-align: center;
      &:after {
        display: block;
        content:'';
        width: 60px;
        height: 1px;
        background-color: #fff;
        margin: 0 auto;
        margin-top: 10px;
      }
    }
    .title {
      color: #fff;
      font-weight: 300;
      line-height: 1.3;
      position: relative;
      z-index: 10;
      text-align: center;
    }
  }
}

.fifth__block {
  padding-top: 150px;
  padding-bottom: 180px;
  .block__number {
    margin-bottom: 25px;
  }
  .block__line {
    width: 140px;
    margin-bottom: 25px;
    &:before, &:after {
      width: 55px;
    }
  }
  .block__title {
    margin-bottom: 160px;
  }
  .news__wrap {
    @extend .flex;
  }
  .news__item {
    width: calc(35% - 30px);
    cursor: pointer;
    @extend .transition;
    margin-right: 30px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:hover {
      box-shadow: 0 17px 76px 0 rgba(50, 44, 116, 0.1);
      .thumb__wrap {
        box-shadow: 0 17px 76px 0 rgba(50, 44, 116, 0.32);
      }
    }
    .thumb__wrap {
      height: 465px;
      position: relative;
      @extend .transition;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .description__wrap {
      background: #f3f3f5;
      padding: 40px 0 50px 0;
      .date-title__wrap {
        @extend .flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 50px;
        margin-bottom: 40px;
        .date {
          background-color: #51568b;
          padding: 12px 25px;
          @extend .flex;
          align-items: center;
          span {
            display: inline-block;
            width: 13px;
            height: 17px;
            position: relative;
            margin-right: 15px;
            &:before {
              display: block;
              content:'';
              border-left: 6.5px solid transparent;
              border-right: 6.5px solid transparent;
              border-bottom: 8px solid #9ba9eb;
            }
            &:after {
              display: block;
              content:'';
              border-left: 6.5px solid transparent;
              border-right: 6.5px solid transparent;
              border-top: 8px solid #fff;
              margin-top: 2px;
            }
          }
          p {
            display: inline-block;
            font-size: 14px;
            font-weight: 300;
            color: #fff;
            text-transform: uppercase;
          }
        }
      }
      .post__text--wrap {
        padding: 0 50px;
        h4 {
          font-size: 20px;
          font-weight: 500;
          line-height: 1.3;
          margin-bottom: 30px;
          height: 50px;
          overflow: hidden;
        }
        p {
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 35px;
          height: 125px;
          overflow: hidden;
        }
        .more__button--wrap {
          width: 100px;
          @extend .transition;
          position: relative;
          &:after {
            display: inline-block;
            content:'';
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 6px solid #51568b;
            position: absolute;
            top: 5.5px;
            right: 0px;
            @extend .transition;
          }
          a {
            display: inline-block;
            font-size: 14px;
            color: #51568b;
            position: relative;
            @extend .transition;
            &:before {
              display: block;
              content:'';
              height: 14px;
              width: 1px;
              background-color: #51568b;
              position: absolute;
              top: 2px;
              right: -10px;
            }
            &:after {
              display: block;
              content:'';
              width: 100%;
              height: 1px;
              background-color: #51568b;
              margin-top: 1px;
              @extend .transition;
            }
          }
          &:hover {
            width: 105px;
            &:after
            a {
              color: #6cb7df;
              &:before {
                background-color: #6cb7df;
              }
              &:after {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}


footer {
  background: #4d4f8c;
  color: #fff;
  position: relative;
  .footer__container {
    position: relative;
    overflow: hidden;
    padding: 90px 0 45px 0;
    &:after {
      display: block;
      content: '';
      width: 500px;
      height: 430px;
      position: absolute;
      right: -140px;
      top: 0;
      background:transparent url('../img/logo-p.svg') center no-repeat;
      background-size: cover;
      opacity: 0.2;
    }
  }
  
  a {
    color: #fff;
  }
  .footer__content--wrap {
    @extend .flex;
  }
  .footer__logo {
    margin-right: 145px;
    img {
      width: 100%;
      max-width: 150px;
    }
  }
  .footer__column {
    margin-right: 150px;
    margin-bottom: 90px;
    ul {
      li {
        margin-bottom: 20px;
        list-style-type: none;
        &:first-child {
          margin-bottom: 20px;
          position: relative;
          z-index: 5;
          &:after {
            display: block;
            content:'';
            width: 40px;
            height: 1px;
            background-color: #fff;
            margin-top: 10px;
          }
          a {
            font-weight: 500;
          }
          &:hover {
            a {
              @extend .transition;
              color: #fff;
              opacity: .8;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: inline-block;
          font-size: 14px;
          font-weight: 300;
          @extend .transition;
          position: relative;
          z-index: 5;
          &:after {
            display: block;
            content:'';
            width: 100%;
            height: 1px;
            background-color: #fff;
            opacity: .5;
            visibility: hidden;
            @extend .transition;
          }
          &:hover {
              color: #fff;
              opacity: .5;
              &:after {
                visibility: visible;
              }
            }
        }
      }
    }
  }
  .footer__bottom--wrap {
    @extend .flex;
    justify-content: space-between;
    p {
      font-size: 12px;
      opacity: .8;
      position: relative;
      z-index: 5;
      a {
        @extend .transition;
         &:hover {
          color: #fff;
          opacity: .8;
        }
      }
    }
    .auctioneer__link {
      margin-left: 20px;
      @extend .transition;
      &:hover {
        opacity: .5;
      }
    }
  }
  .top__top {
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    margin-top: auto;
    margin-right: auto;
    text-align: center;
    cursor: pointer;
    &:hover {
      p {
        opacity: .5;
      }
    }
    span {
      display: inline-block;
      border-left: 6px solid #4d4f8c;
      border-top: 6px solid #4d4f8c;
      transform: rotate(45deg);
      width: 41px;
      height: 41px;
    }
    p {
      font-size: 14px;
      font-weight: 300;
      opacity: .8;
      @extend .transition;
    }
  }
}


//Inner page
.page__inner {
  margin-top: 130px;
  .inner__banner {
    position: relative;
    height: 320px;
    padding-left: 320px;
    @extend .flex;
    align-items: center;
    &:before {
      display: block;
      content:'';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(50, 44, 116, 0.6);
      z-index: 2;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    ul.breadcrumbs {
      position: relative;
      z-index: 4;
      margin-bottom: 35px;
      li {
        font-weight: 300;
        color: #fff;
        display: inline-block;
        list-style-type: none;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      span, a {
        font-weight: 300;
        color: #fff;
        @extend .transition;
      }
      a {
        &:hover {
          opacity: .6;
        }
      }
    }
    h1 {
      position: relative;
      z-index: 4;
      color: #fff;
      font-size: 64px;
      font-weight: 500;
      line-height: .8;
    }
  }
  //Page aout
  .content__block {
    @extend .flex;
    .left__side {
      padding: 100px 170px 20px 325px;
      width: 57%;
      .more__button {
        display: block;
        width: 200px;
        padding: 19px 0;
        margin-bottom: 90px;
        color: #322c74;
        border: 1px solid #322c74;
        text-align: center;
        @extend .transition;
        opacity: 0;
        &:hover {
          color: #fff;
          background-color: #322c74;
        }
      }
      h4 {
        font-weight: 400;
        font-size: 48px;
        margin-bottom: 45px;
      }
      .undertitle {
        font-family: $second-font-family;
        font-size: 14px;
        margin-bottom: 40px;
      }
      p {
        font-size: 14px;
        margin-bottom: 50px;
        line-height: 1.7;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          font-weight: 600;
        }
      }
    }
    .right__side {
      width: 43%;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    &:nth-child(odd) {
      background-color: #f7f7f8;
    }
    &:nth-child(even) {
      background-color: #edeff7;
      flex-direction: row-reverse;
      .left__side {
        padding: 170px 325px 20px 170px;
      }
      .right__side {
        padding: 150px 0;
      }
    }
    &:hover {
      .more__button {
        opacity: 1;
      }
    }
  }
  .bottom__links--wrap {
    @extend .flex;
    justify-content: space-between;
    padding: 180px 0;
    .bottom__item {
     width: 100%;
     max-width: 350px;
     height: 340px;
     position: relative;
     padding: 90px 45px;
     outline: 1px solid #edeff7;
     @extend .transition;
     @extend .flex;
     flex-direction: column;
     &:before {
      display: block;
      content:'';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(50, 44, 116, 0.6);
      z-index: 2;
      @extend .transition;
      opacity: 0;
    }
     img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      @extend .transition;
      opacity: 0;
     }
     h4 {
      position: relative;
      z-index: 2;
      color: $base-font-color;
      @extend .transition;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 25px;
      text-align: center;
     }
     p {
      position: relative;
      z-index: 2;
      color: $base-font-color;
      @extend .transition;
      font-size: 14px;
      line-height: 1.8;
     }
     &:hover {
      transform: scaleY(1.15);
      justify-content: flex-end;
      padding-bottom: 60px;
      box-shadow: 0 17px 76px 0 rgba(50, 44, 116, 0.11);
      &:before {
        opacity: 1;
      }
      img {
        opacity: 1;
      }
      h4 {
        margin-bottom: 15px;
      }
      h4, p {
        color: #fff;
      }
     }
    }
  }
  //Page documentation
  .documentation__wrap, .auctioneer__wrap {
    padding: 120px 0 170px;
    .search__wrap {
      @extend .flex;
      justify-content: space-between;
      margin-bottom: 100px;
      form {
        input {
          width: 400px;
          border: 1px solid #edeff7;
          padding: 16px 0 15px 35px;
          font-size: 16px;
          display: inline-block;
          margin-right: 3px;
          max-height: 50px;
          @extend .transition;
          &:focus {
            outline: none;
            border-color: #51568b;
          }
        }
        .search-submit {
          width: 180px;
          padding: 19px 0 18px;
          text-align: center;
          color: #fff;
          background-color: #51568b;
          font-size: 14px;
          border: 0;
          max-height: 50px;
          @extend .transition;
          &:hover {
            background-color: #6cb7df;
          }
        }
      }
      .search__results--count {
        p {
          font-size: 14px;
          span {
            font-weight: 500;
          }
        }
      }
    }
    .documentation__table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 4px;
      position: relative;
      tr {
        th {
          background-color: #51568b;
          position: relative;
          text-align: left;
          cursor: pointer;
          @extend .transition;
          font-size: 14px;
          color: #fff;
          padding-left: 35px;
          a {
            display: block;
            font-size: 14px;
            color: #fff;
            padding: 23px 0 23px 0;
          }
          &:hover {
            background-color: #6cb7df;
            box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
            &:before {
              transform: rotate(180deg);
            }
          }
          &:before {
            display: block;
            content:'';
            width: 8px;
            height: 4px;
            background:transparent url('../img/arrow-up.png') center no-repeat;
            position: absolute;
            right: 25px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            @extend .transition;
          }
          &:first-child {
            width: 72.4%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 12.6%;
            &:before {
              background:transparent url('../img/download-icon.png') center no-repeat;
              width: 21px;
              height: 20px;
              right: 37px;
            }
            &:hover {
              &:before {
                transform: none;
              }
            }
          }
        }
        &:nth-child(2) {
          td {
            width: 100%;
            padding: 23px 0;
            font-size: 14px;
            font-weight: 500;
            background-color: #8e94c1;
            text-align: center;
            position: absolute;
            top: 68px;
            color: #fff;
            max-width: 1530px;
            width: 99.5%;
          }
        }
      }
    }
    .documentation__table--second {
      width: 100%;
      border-collapse: separate;
      border-spacing: 4px;
      position: relative;
      tr {
        &:first-child {
          td {
            a {
              @extend .transition;
              opacity: 1;
            }
          }
        }
        @extend .transition;
        td {
          background-color: #fbfbfc;
          font-size: 14px;
          font-weight: 500;
          color: $base-font-color;
          padding: 23px 0 23px 35px;
          position: relative;
          text-align: left;
          cursor: pointer;
          @extend .transition;
          a {
            color: $base-font-color;
          }
          span {
            float: right;
            font-size: 14px;
            color: #fff;
            @extend .transition;
            opacity: 0;
          }
          &:first-child {
            width: 72.4%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 12.6%;
            padding-right: 65px;
            position: relative;
            &:before {
              display: block;
              content:'';
              background:transparent url('../img/flie-icon-1.png') center no-repeat;
              width: 21px;
              height: 21px;
              top: 0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              right: 37px;
              position: absolute;
            }
          }
        }
        &:nth-child(even) {
          td {
            background-color: #f3f3f5;
          }
        }
        &:hover {
            td {
              background-color: #6cb7df;
              box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
              color: #fff;
              a {
                color: #fff;
              }
              span {
                opacity: 1;
              }
              &:before {
                background:transparent url('../img/file-icon.png') center no-repeat;
              }
            }
          }
      }
    }
    .more__documents {
      display: block;
      width: 99.75%;
      padding: 21px 0;
      text-align: center;
      color: #fff;
      background-color: #6cb7df;
      box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
      font-size: 14px;
    }
  }

  .documentation__wrap {
    .documentation__table {
      tr {
        th {
          &:first-child {
            width: 40%;
          }
          &:nth-child(2) {
            width: 35%;
          }
          &:nth-child(3) {
            width: 10%;
            &:before {
              display: block;
              content:'';
              width: 8px;
              height: 4px;
              background:transparent url('../img/arrow-up.png') center no-repeat;
              position: absolute;
              right: 25px;
              @extend .transition;
            }
            &:hover {
              &:before {
                transform: rotate(180deg);
              }
            }
          }
          &:nth-child(4) {
            width: 13%;
            a {
              display: block;
              position: relative;
              text-indent: -1000px;
              overflow: hidden;
              &:before {
                background:transparent url('../img/download-icon.png') center no-repeat;
                width: 21px;
                height: 20px;
                right: 37px;
              }
            }
            &:hover {
              a {
              	text-indent: 0;
                &:before {
                  transform: none;
                }
              }
            }
          }
        }
      }
    }
    .documentation__table--second {
      tr {
        &:first-child {
          td {
            a {
              @extend .transition;
              opacity: 1;
            }
          }
        }
        td {
          &:first-child {
            width: 40%;
          }
          &:nth-child(2) {
            width: 35%;
            text-decoration: underline;
          }
          &:nth-child(3) {
            width: 10%;
            padding: 23px 5px 23px 5px;
            text-align: center;
            &:before {
              display: none;
            }
          }
          &:nth-child(4) {
            width: 13%;
            padding: 0;
            a {
              display: block;
              position: relative;
              text-indent: -1000px;
              transition: none;
              overflow: hidden;
              padding: 23px 0 23px 35px;
              &:before {
                display: block;
                content:'';
                background:transparent url('../img/flie-icon-1.png') center no-repeat;
                width: 21px;
                height: 21px;
                right: 37px;
                position: absolute;
              }
            }
          }
        }
        &:hover {
          td {
            &:nth-child(4) {
              &:before {
                background:transparent url('../img/file-icon.png') center no-repeat;
              }
              a {
                text-indent: 0;
              }
            }
          }
        }
      }
    }
  }

  .auctioneer__page {
    padding: 120px 0 170px;
    .search__wrap {
      @extend .flex;
      justify-content: space-between;
      margin-bottom: 100px;
      form {
        input {
          width: 400px;
          border: 1px solid #edeff7;
          padding: 16px 0 15px 35px;
          font-size: 16px;
          display: inline-block;
          margin-right: 3px;
          max-height: 50px;
          @extend .transition;
          &:focus {
            outline: none;
            border-color: #51568b;
          }
        }
        .search-submit {
          width: 180px;
          padding: 19px 0 18px;
          text-align: center;
          color: #fff;
          background-color: #51568b;
          font-size: 14px;
          border: 0;
          max-height: 50px;
          @extend .transition;
          &:hover {
            background-color: #6cb7df;
          }
        }
      }
      .search__results--count {
        p {
          font-size: 14px;
          span {
            font-weight: 500;
          }
        }
      }
    }
    .documentation__table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 4px;
      position: relative;
      margin-bottom: 0 !important;
      tr {
        th {
          background-color: #51568b;
          position: relative;
          text-align: left;
          cursor: pointer;
          @extend .transition;
          padding-left: 35px;
          font-size: 14px;
          color: #fff;
          a {
            display: block;
            font-size: 14px;
            color: #fff;
            padding: 23px 0 23px 0;
          }
          &:hover {
            background-color: #6cb7df;
            box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
            &:before {
              transform: rotate(180deg);
            }
          }
          &:before {
            display: block;
            content:'';
            width: 8px;
            height: 4px;
            background:transparent url('../img/arrow-up.png') center no-repeat;
            position: absolute;
            right: 25px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            @extend .transition;
          }
          &:first-child {
            width: 72.4%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 12.6%;
            &:before {
              background:transparent url('../img/download-icon.png') center no-repeat;
              width: 21px;
              height: 20px;
              top: 0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              right: 37px;
            }
            &:hover {
              &:before {
                transform: none;
              }
            }
          }
        }
        &:nth-child(2) {
          td {
            width: 100%;
            padding: 23px 0;
            font-size: 14px;
            font-weight: 500;
            background-color: #8e94c1;
            text-align: center;
            position: absolute;
            top: 68px;
            color: #fff;
            max-width: 1530px;
            width: 99.5%;
          }
        }
      }
    }
    .documentation__table--second {
      width: 100%;
      border-collapse: separate;
      border-spacing: 4px;
      position: relative;
      tr {
        &:first-child {
          td {
            a {
              @extend .transition;
              opacity: 1;
            }
          }
        }
        @extend .transition;
        td {
          background-color: #fbfbfc;
          font-size: 14px;
          font-weight: 500;
          color: $base-font-color;
          padding: 23px 0 23px 35px;
          position: relative;
          text-align: left;
          cursor: pointer;
          @extend .transition;
          a {
            color: $base-font-color;
          }
          span {
            float: right;
            font-size: 14px;
            color: #fff;
            @extend .transition;
            opacity: 0;
          }
          &:first-child {
            width: 72.4%;
          }
          &:nth-child(2) {
            width: 10%;
            text-decoration: none;
            text-align: center;
            padding: 23px 5px 23px 5px;
          }
          &:nth-child(3) {
            width: 12.6%;
            padding: 0;
            position: relative;
            text-align: left;
            a {
              position: relative;
              display: block;
              padding: 23px 5px 23px 50px;
              text-indent: -500px;
    		  overflow: hidden;
    		  transition: none;
              &:before {
                display: block;
                content:'';
                background:transparent url('../img/flie-icon-1.png') center no-repeat;
                width: 21px;
                height: 21px;
                top: 0;
                bottom: 0;
                margin-top: auto;
                margin-bottom: auto;
                right: 37px;
                position: absolute;
              }
            }
          }
        }
        &:nth-child(even) {
          td {
            background-color: #f3f3f5;
          }
        }
        &:hover {
            td {
              background-color: #6cb7df;
              box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
              color: #fff;
              a {
                color: #fff;
                text-indent: 0;
              }
              span {
                opacity: 1;
              }
              &:before {
                background:transparent url('../img/file-icon.png') center no-repeat;
              }
            }
          }
      }
    }
    .more__documents {
      display: block;
      width: 99.75%;
      padding: 21px 0;
      text-align: center;
      color: #fff;
      background-color: #6cb7df;
      box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
      font-size: 14px;
    }
  }

  //Page auctioneer
  .auctioneer__wrap {
    .content__title {
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 30px;
    }
    .block__line {
      width: 140px;
      margin-bottom: 100px;
      &:before, &:after {
        width: 55px;
      }
    }
  }
  //Page products
  .products__wrap {
    padding: 120px 0 210px 0;
    .products__inner--wrap {
      @extend .flex;
      flex-wrap: wrap;
    }
    .product__cat {
      width: 33%;
      max-width: 33%;
      display: block;
      position: relative;
      margin-bottom: 100px;
      .image__wrap {
        position: relative;
        height: 240px;
        margin-bottom: 20px;
        @extend .transition;
        @extend .flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        .show__product--link {
          text-align: center;
          opacity: 0;
          visibility: hidden;
          @extend .transition;
          font-size: 12px;
          font-weight: 300;
          color: #fff;
          text-decoration: underline;
        }
      }
      img {
        max-height: 220px;
        width: auto;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
        position: relative;
        z-index: 5;
        @extend .transition;
      }
      .bottom__bg {
        height: 260px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -150px;
        background: transparent url('../img/logo-p-1.svg') no-repeat 130% 100%;
        @extend .transition;
        @extend .flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 35px;
      }
      &:hover {
        .image__wrap {
            transform: translateY(-10px);
            img {
              transform: scale(1.1);
            }
            .show__product--link {
              opacity: 1;
              visibility: visible;
            }
        }
        .bottom__bg {
          background: #6cb7df url('../img/logo-p.svg') no-repeat 300% 100%;
          box-shadow: 0 20px 15px 0 rgba(108, 183, 223, 0.5);
        }
        .description__block {
          transform: translateY(-10px);
          &:before {
            background-color: #fff;
          }
          h4 {
            a {
              color: #fff;
            }
          }
        }
      }
      .description__block {
        padding-top: 35px;
        position: relative;
        @extend .transition;
        &:before {
          display: block;
          content:'';
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          top: 0;
          height: 20px;
          width: 1px;
          background-color: #8c8c8c;
          @extend .transition;
        }
        h4 {
          text-align: center;
          @extend .transition;
          a {
            color: $base-font-color;
          }
        }
      }
    }
  }

  .products__all--wrap {
    .product__cat {
      width: 33%;
      max-width: 490px;
      height: 300px;
      display: -webkit-flex !important;
       display: -moz-flex !important;
       display: -ms-flex !important;
       display: -o-flex !important;
       display: flex !important;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 0;
      margin-right: 30px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .image__wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        img {
          max-height: 100%;
        }
        a {
          width: 100%;
          height: 100%;
          display: block;
          &:before {
            display: block;
            content:'';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background:rgba(0, 0, 0, .6);
            @extend .transition;
            z-index: 10;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        @extend .transition;
      }
      &:hover {
        box-shadow: 0 17px 76px 0 rgba(50, 44, 116, 0.46);
        .image__wrap {
          a {
            &:before {
              background: rgba(77, 79, 140, 0.7);
              transform: scaleY(1.1);
            }
          }
            img {
              transform: scaleY(1.1);
            }
            .show__product--link {
              opacity: 1;
              visibility: visible;
            }
        }
      }
      h4 {
        position: relative;
        z-index: 20;
        text-align: center;
        a {
          font-size: 24px;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }

//Product category
.products__cat--wrap {
  padding: 100px 0 0 0;
  .list__title {
    display: block;
    padding: 22px 0 22px 35px;
    background-color: #51568b;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    cursor: auto;
    margin-bottom: 5px;
  }
  .tabs {
    li {
      list-style-type: none;
      padding: 22px 0 22px 35px;
      font-size: 14px;
      background-color: #f3f3f5;
      margin-bottom: 5px;
      position: relative;
      @extend .transition;
      cursor: pointer;
      a {
        font-size: 14px;
        color: #333;
      }
      &:nth-child(odd) {
        background-color: #fbfbfc;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.current {
        padding-left: 60px;
        font-weight: 700;
        cursor: auto;
        a {
          font-weight: 700;
        }
        &:before {
          display: block;
          content:'';
          width: 0; 
          height: 0; 
          border-top: 6.5px solid transparent;
          border-bottom: 6.5px solid transparent;
          border-left: 8px solid #51568b;
          position: absolute;
          top: 24px;
          left: 35px;
          @extend .transition;
        }
      }
      &:hover {
        color: #fff;
        background-color: #51568b;
        a {
          color: #fff;
        }
        &:before {
          border-left-color: #fff;
        }
      }
    }
  }
  .products__inner--wrap {
    justify-content: flex-start;
  }
  .product__cat {
    max-width: 360px;
    margin-right: 30px;
    margin-bottom: 120px;
    .bottom__bg {
      background:#51568b url('../img/logo-p.svg') 130% top no-repeat;
      height: 150px;
      bottom: 0;
    }
    .description__block {
      h4 {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
      }
      p {
        font-size: 14px;
        font-family: $second-font-family;
        line-height: 1.5;
        padding-left: 35px;
      }
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 170px;
    }
    &:hover {
      .bottom__bg {
          background: #6cb7df url(../img/logo-p.svg) no-repeat 140% 100%;
      }
      .description__block {
        h4 {
          a {
            color: $base-font-color;
          }
        }
      }
    }
  }
  .wp-pagenavi {
    @extend .flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 210px;
    a {
      margin-right: 65px;
      width: 50px;
      height: 50px;
      font-size: 18px;
      @extend .transition;
      @extend .flex;
      align-items: center;
      justify-content: center;
      color: $base-font-color;
      &:hover {
        color: #fff;
        font-weight: 700;
        background-color: #4d4f8c;
      }
    }
    .current {
      margin-right: 65px;
      width: 50px;
      height: 50px;
      font-size: 18px;
      @extend .transition;
      color: #fff;
      font-weight: 700;
      background-color: #4d4f8c;
      @extend .flex;
      align-items: center;
      justify-content: center;
    }
    .previouspostslink {
      display: inline-block;
      width: 50px;
      height: 50px;
      border: 1px solid #4d4f8c;
      position: relative;
      margin-right: 80px;
      &:before {
        display: block;
        content:'';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background:transparent url('../img/back.svg') center no-repeat;
        background-size: 20px;
      }
    }
    .nextpostslink {
      display: inline-block;
      width: 50px;
      height: 50px;
      border: 1px solid #4d4f8c;
      position: relative;
      margin-left: 15px;
      &:before {
        display: block;
        content:'';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background:transparent url('../img/back.svg') center no-repeat;
        background-size: 20px;
        transform: rotate(180deg);
      }
    }
  }
}

//Single products
.single__product {
  padding: 125px 0 0 0;
  .container {
    width: 1300px;
  }
  .product__wrap {
    @extend .flex;
    margin-bottom: 135px;
  }
  .sidebar {
    max-width: 490px;
    width: 31%;
    margin-right: 100px;
    #my-slider {
      margin-bottom: 50px;
    }
    .sp-arrows {
      display: none !important;
    }
    .top__image {
      .sp-image-container {
        @extend .flex;
        align-items: center;
        justify-content: center;
      }
      .bottom__bg {
        height: 200px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #51568b url('../img/logo-p.svg') no-repeat 130% 100%;
        @extend .transition;
        @extend .flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 35px;
      }
      img {
        position: relative;
        z-index: 5;
        display: block;
        margin: 0 auto !important;
        height: 100%;
        object-fit: contain;
        max-width: 90%;
        max-height: 380px;
      }
    }
    .sp-thumbnails-container {
     margin-top: 20px;
      .sp-thumbnail-container {
        overflow: visible;
        margin-right: 20px;
        opacity: .3;
        &.sp-selected-thumbnail {
          opacity: 1;
          .sp-thumbnail {
            border: 1px solid #caccdc;
          }
        }
      }
      .sp-thumbnail {
        position: relative;
        height: 100%;
        border: 1px solid #efeeee;
        border-top: 0;
        top: 0;
        &:before {
          display: block;
          content:'';
          width: 70px;
          height: 1px;
          background-color: #efeeee;
          position: absolute;
          top: 0;
          left: 0;
        }
        &:after {
          display: block;
          content:'';
          width: 70px;
          height: 1px;
          background-color: #efeeee;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .sp-thumbnail-image-container {
        height: 100%;
      }
      img {
        display: block;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin-top: auto;
        margin-bottom: auto;
        max-width: 85%;
        max-height: 85px;
      }
    }
    .specifications {
      margin-bottom: 100px;
      h4 {
        font-size: 24px;
        margin-bottom: 40px;
        padding-left: 35px;
      }
      ul {
        li {
          font-size: 14px;
          line-height: 1;
          padding: 14px 0 13px 60px;
          background-color: #fbfbfc;
          margin-bottom: 4px;
          list-style-type: none;
          position: relative;
          &:before {
            display: block;
            content:'';
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #6cb7df;
            position: absolute;
            left: 35px;
            top: 17px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:nth-child(odd) {
            background-color: #f3f3f5;
          }
          span {
            font-weight: 700;
          }
        }
      }
    }
    .documents {
      h4 {
        font-size: 24px;
        margin-bottom: 20px;
      }
      ul {
        li {
          list-style-type: none;
          margin-bottom: 4px;
          position: relative;
          background-color: #f3f3f5;
          a {
            background-color: #f3f3f5;
            position: relative;

            &:before {
              display: block;
              content:'';
              background:transparent url('../img/flie-icon-1.png') center no-repeat;
              width: 21px;
              height: 21px;
              top:0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              right: 20px;
              position: absolute;
              @extend .transition;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:nth-child(even) {
            background-color: #fbfbfc;
            a {
              background-color: #fbfbfc;
            }
          }
          a {
            display: block;
            padding: 24px 40px 24px 30px;
            font-size: 14px;
            font-weight: 700;
            color: $base-font-color;
            @extend .transition;
          }
          &:hover {
            a {
              background-color: #6cb7df;
              box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
              color: #fff;
            }
             &:before {
                background:transparent url('../img/file-icon.png') center no-repeat;
              }
            }
            &.non__logged {
              font-weight: 700;
              padding: 24px 35px;
              background-color: #fbfbfc;
              &:before {
                display: none;
              }
              a {
                display: inline;
                background: transparent;
                padding: 0;
                font-weight: 700;
              }
              &:hover {
                
                a {
                  color: #212121;
                  background-color: transparent;
                }
              }
            }
        }
      }
    }
  }
  .main__content {
    max-width: 780px;
    width: 69%;
    .content__title {
      font-size: 36px;
      margin-bottom: 40px;
    }
    .undertitle {
      font-family: $second-font-family;
      font-weight: 300;
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 40px;
      font-style: italic;
      color: #717171;
    }
    .table__upper--text {
      font-size: 14px;
      line-height: 1.7;
      margin-bottom: 45px;
      span {
        font-weight: 700;
      }
    }
    .table__title {
      font-size: 18px;
      line-height: 1;
      margin-bottom: 25px;
    }
    .single__char {
      div {
        padding: 25px 35px;
        margin-bottom: 5px;
        background-color: #f9f9fa;
        @extend .flex;
        align-items: center;
        &:nth-child(odd) {
          background-color: #f3f3f5;
        }
        &:last-child {
          margin-bottom: 0;
        }
        h6 {
          font-size: 14px;
          font-weight: 500;
          width: 45%;
        }
        p {
          font-size: 14px;
          font-weight: 300;
          width: 55%;
        }
      }
    }
    .advantages {
      margin: 100px 0 100px;
      h4 {
        font-size: 24px;
        margin-bottom: 40px;
      }
    }

  .advantages__item {
      .content__row {
        @extend .flex;
        border: 1px solid #efeeee;
        margin-bottom: 4px;
      }
      .left {
        width: 35%;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        padding: 55px 0 0 60px;
        background-color: #51568b;
      }
      .right {
        width: 65%;
        padding: 55px 60px 60px 65px;
        p {
          font-size: 14px;
          line-height: 1.7;
          margin-bottom: 0;
        }
      }
    }
    .description {
      margin-bottom: 100px;
      h4 {
        font-size: 24px;
        margin-bottom: 45px;
      }
      p {
        font-size: 14px;
        color: #717171;
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .single__product--bottom {
    margin-bottom: 100px;
    p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 35px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    h6 {
      font-size: 18px;
      margin-bottom: 20px;
    }
    ul {
      @extend .flex;
      flex-direction: column;
      margin-bottom: 50px;
      li {
        list-style-type: none;
        padding-left: 20px;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 20px;
        position: relative;
        &:before {
          display: block;
          content:'';
          width: 0; 
          height: 0; 
          border-top: 4.5px solid transparent;
          border-bottom: 4.5px solid transparent;
          border-left: 8px solid #51568b;
          position: absolute;
          left: 0;
          top: 3px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .specifications__tabs {
    margin: 65px 0;
    .sprec__tabs__items--wrap {
      .current__tab {
        display: none;
      }
    }
  }

  .sprec__tabs__items {
      margin-bottom: 10px;
      @extend .flex;
      border-bottom: 3px solid #6cb7df;
      flex-direction: row;
      li {
        list-style-type: none;
        cursor: pointer;
        font-size: 14px;
        padding: 25px 30px;
        font-weight: 500;
        @extend .transition;
        width: 34%;
        @extend .flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #f3f3f5;
        opacity: .5;
        margin-right: 3px;
        margin-bottom: 0;
        &:before {
          display: none;
        }
        &:last-child {
          margin-right: 0;
        }
        &.current {
          background-color: #6cb7df;
          opacity: 1;
          color: #fff;
        }
        &:hover {
          background-color: #6cb7df;
          color: #fff;
        }
      }
    }

    .spec__tab--content--wrap {
      .accordeon__content {
        padding: 40px 30px 50px 85px;
        margin-bottom: 4px;
        background-color: #f3f3f5;
        @extend .transition;
        h6 {
          font-size: 18px;
          margin-bottom: 20px;
        }
        p {
          font-size: 14px;
          line-height: 1.7;
          position: relative;
          margin-bottom: 25px;
        }
        ul {
          padding-left: 30px;
          li {
            color: #717171;
            margin-bottom: 15px;
            list-style-type: none;
            font-size: 14px;
            position: relative;
            &:before {
              display: block;
              content:'';
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background-color: #6cb7df;
              position: absolute;
              left: -30px;
              top: 7px;
              border: 0;
            }
          }
        }
      }
      .spec__tab--content {
        &:nth-child(2) {
          .accordeon__content {
            background-color: transparent;
            padding: 0;
            h6 {
               margin: 40px 0 20px 0;
            }
            .content__row {
              margin-bottom: 4px;
              &:nth-child(even) {
                .left, .right {
                  background-color: #f3f3f5;
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
              @extend .flex;
              .left {
                width: 47%;
                font-size: 14px;
                font-weight: 500;
                margin-right: 4px;
                background-color: #fbfbfc;
                padding: 23px 25px 22px 35px;
              }
              .right {
                width: 53%;
                font-size: 14px;
                background-color: #fbfbfc;
                padding: 23px 25px 22px 35px;
              }
            }
          }
        }
        &:nth-child(3) {
          table {
            width: 100%;
            tr {
              &:first-child {
                border-bottom: 1px solid #000;
              }
              &:nth-child(even) {
                background-color: #fff;
              }
              td {
                text-transform: uppercase;
                padding: 5px;
                &:first-child {
                  width: 35%;
                }
              }
            }
          }
        }
      }
    }
  }

  .prod__navigation--wrap {
    margin-top: 120px;
    margin-bottom: 170px;
    @extend .flex;
    a {
      background: #ededf3;
      background-size: 40px;
      padding: 50px 0;
      color: $base-font-color;
      font-size: 20px;
      font-weight: 500;
      position: relative;
      @extend .transition;
      max-height: 120px;
      span {
        display: none;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
      }
      &:hover {
        background-color: #6cb7df;
        box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
        color: #fff;
        transform: translateY(-8px);
        font-size: 16px;
        font-weight: 400;
        padding-top: 35px;
        span {
          display: block;
        }
      }
    }
    .prev {
      width: 50%;
      margin-right: 5px;
      padding-left: 130px;
      &:before {
        display: block;
        content:'';
        width: 0; 
        height: 0; 
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 10px solid #000;
        position: absolute;
        left: 100px;
        top: 53px;
        @extend .transition;
      }
      &:hover {
        &:before {
          border-right-color: #fff;
        }
      }
    }
    .next {
      background-size: 40px;
      width: 50%;
      padding-right: 130px;
      text-align: right;
      &:before {
        display: block;
        content:'';
        width: 0; 
        height: 0; 
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 10px solid #000;
        position: absolute;
        right: 100px;
        top: 53px;
        @extend .transition;
      }
      &:hover {
        &:before {
          border-left-color: #fff;
        }
      }
    }
  }
}

//Contacts page
  .contacts__inner {
    padding: 125px 0 140px 0;
    .content__title {
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 25px;
    }
    .block__line {
      width: 140px;
      margin-bottom: 25px;
      &:before, &:after {
        width: 55px;
      }
    }
    .undertitile {
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 100px;
      font-family: $second-font-family;
      font-style: italic;
      line-height: 1.5;
      color: #4d4d4d; 
      font-size: 14px;
      text-align: center;
    }
    #map {
      width: 100%;
      height: 885px;
      box-shadow: 0 20px 87px 0 #efeeec;
      margin-bottom: 170px;
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
    .contacts__info--wrap {
      max-width: 1475px;
      margin: 0 auto;
      .col-md-12 {
        @extend .flex;
      }
      .left {
        margin-right: 100px;
      }
      h4 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 40px;
        text-align: center;
      }
      .block__line {
        margin-bottom: 50px;
      }
      .contacts__cards--wrap {
        @extend .flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 750px;
        .card {
          width: 48%;
          max-width: 360px;
          padding: 45px 90px 40px 90px;
          background-color: #51568b;
          color: #fff;
          background-repeat: no-repeat;
          background-position: 110% 100%;
          margin-bottom: 30px;
          cursor: pointer;
          @extend .transition;
          &:hover {
            transform: translateY(-8px);
            background-color: #6cb7df;
            box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
          }
          h6 {
            font-size: 18px;
            margin-bottom: 20px;
            position: relative;
            &:before {
              display: block;
              content:'';
              width: 30px;
              height: 25px;
              position: absolute;
              top: -3px;
              left: -40px;
            }
          }
          a, p {
            color: #fff;
            font-size: 14px;
            span {
              font-weight: 700;
            }
          }
          a {
            &:hover {
              text-decoration: underline;
            }
          }
          &.adress {
            background-image: url(../img/adress-icon.png);
            background-size: 100px;
            h6 {
              &:before {
                background:transparent url('../img/a.png') center no-repeat;
              }
            }
            p {
              line-height: 1.7;
            }
          }
          &.email {
            background-image: url(../img/email-icon.png);
            background-size: 100px;
            h6 {
              margin-bottom: 30px;
              &:before {
                background:transparent url('../img/e.png') center no-repeat;
              }
            }
          }
          &.phones {
            background-image: url(../img/phone-icon.png);
            background-size: 70px;
            background-position: 103% 100%;
            h6 {
              &:before {
                background:transparent url('../img/p.png') center no-repeat;
              }
            }
            .phone {
              margin-bottom: 15px;
            }
            .mobile__phone {
              display: none;
            }
          }
          &.team {
            background-image: url(../img/team-icon.png);
            background-size: 70px;
            background-position: 103% 100%;
            h6 {
              &:before {
                background:transparent url('../img/t.png') center no-repeat;
              }
            }
          }
        }
      }
      .right {
        max-width: 620px;
      }
      form {
        position: relative;
        .wpcf7-form-control-wrap {
          display: inline-block;
          width: 50%;
          max-width: 295.5px;
          margin-bottom: 35px;
          input {
            width: 100%;
            font-family: $second-font-family;
            border: 1px solid #ebebeb;
            background-color: #fff;
            padding: 17px 0 17px 30px;
            font-size: 14px;
            font-weight: 600;
            @extend .transition;
            &::-webkit-input-placeholder {
              font-family: $second-font-family;
              font-size: 14px;
              font-weight: 600;
              color: #000;
            }
            &::-moz-placeholder {
              font-family: $second-font-family;
              font-size: 14px;
              font-weight: 600;
              color: #000;
            }
            &:-ms-input-placeholder {
              font-family: $second-font-family;
              font-size: 14px;
              font-weight: 600;
              color: #000;
            }
            &:-moz-placeholder { 
              font-family: $second-font-family;
              font-size: 14px;
              font-weight: 600;
              color: #000;
            }
            &:focus {
              background:#6cb7df  url(../img/email-icon.png) no-repeat 105% 100%;
              background-size: 40px;
              box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
              color: #fff;
              outline: none;
            }
          }
          &:first-child {
            margin-right: 25px;
          }
          &:nth-child(3) {
            margin-bottom: 43px;
            width: 100%;
            max-width: 100%;
            textarea {
              width: 100%;
              height: 190px;
              padding: 30px;
              font-family: $second-font-family;
              border: 1px solid #ebebeb;
              background-color: #fff;
              font-size: 14px;
              font-weight: 600;
              @extend .transition;
              &::-webkit-input-placeholder {
                font-family: $second-font-family;
                font-size: 14px;
                font-weight: 600;
                color: #000;
              }
              &::-moz-placeholder {
                font-family: $second-font-family;
                font-size: 14px;
                font-weight: 600;
                color: #000;
              }
              &:-ms-input-placeholder {
                font-family: $second-font-family;
                font-size: 14px;
                font-weight: 600;
                color: #000;
              }
              &:-moz-placeholder { 
                font-family: $second-font-family;
                font-size: 14px;
                font-weight: 600;
                color: #000;
              }
              &:focus {
                background:#6cb7df  url(../img/email-icon.png) no-repeat 102% 100%;
                background-size: 40px;
                box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
                color: #fff;
                outline: none;
              }
            }
          }
        }
        .send__button {
          display: block;
          width: 200px;
          padding: 17px 0;
          text-align: center;
          color: #fff;
          background-color: #51568b;
          border: 0;
          margin: 0 auto;
          @extend .transition;
          &:hover {
            outline: none;
            background:#6cb7df;
            box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
          }
        }
        .successful {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          text-align: center;
          color: #fff;
          background: #51568b;
          padding: 20px 0;
        }
      }
    }
  }

  //Page Team
  .team__inner, .problems__inner {
    padding-top: 120px;
    background-color: #f0f0f2;
    .content__title {
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 25px;
    }
    .block__line {
      width: 140px;
      margin-bottom: 25px;
      &:before, &:after {
        width: 55px;
      }
    }
    .undertitile {
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 220px;
      font-family: $second-font-family;
      font-style: italic;
      line-height: 1.5;
      color: #4d4d4d; 
      font-size: 14px;
      text-align: center;
    }
    .team__cards--wrap {
      margin-bottom: 170px;
    }
    .team__member {
      max-width: 1140px;
      width: 100%;
      height: 380px;
      margin: 0 auto;
      margin-bottom: 180px;
      background:#4d4f8c url(../img/logo-p.svg) 115px 100% no-repeat;
      background-size: 350px;
      @extend .flex;
      transition: 1s all ease-in-out;
      position: relative;
      padding: 55px 110px 60px 410px;
      color: #fff;
      cursor: pointer;
      img {
        position: absolute;
        left: -35px;
        top: -120px;
        width: 360px;
        height: 475px;
        object-fit: cover;
      }
      h4 {
        font-size: 30px;
        margin-bottom: 15px;
      }
      .position {
        font-family: $second-font-family;
        font-style: italic;
        margin-bottom: 30px;
      }
      .description {
        font-family: $second-font-family;
        font-size: 14px;
        line-height: 1.8  ;
        margin-bottom: 40px;
      }
      .member__email {
        font-family: $second-font-family;
        font-size: 14px;
        color: #fff;
        padding-left: 25px;
        background:transparent url('../img/envelope.png') left center no-repeat;
      }
      &:hover {
        background-color: #6cb7df;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .all__members {
      position: relative;
      overflow: hidden;
      padding-bottom: 170px;
      &:before {
        display: block;
        content:'';
        width: 150%;
        height: 110%;
        background: #e6e8f1;
        transform: rotate(-3deg);
        position: absolute;
        left: -160px;
        top: 450px;
      }
      .undertitile {
        margin-bottom: 100px;
        position: relative;
        z-index: 2;
      }
      .members__image--wrap {
        width: 100%;
        box-shadow: 0 14px 142px 0 rgba(21, 18, 22, 0.33);
        position: relative;
        z-index: 2;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: relative;
          z-index: 2;
        }
      }
    }
  }

  //Page problems
  .problems__inner {
    background-color: #fff;
    .problem__block {
      max-width: 1385px;
      padding: 75px 135px 75px 600px;
      height: auto;
      background: #4d4f8c url(../img/logo-p.svg) 130% 100% no-repeat;
      background-size: 500px;
      img {
        width: 490px;
        height: 575px;
        left: -15px;
        top: unset;
        bottom: 30px;
      }
      h4 {
        margin-bottom: 40px;
      }
      .position {
        font-weight: 300;
        line-height: 1.5;
        margin-bottom: 40px;
      }
      .to__article {
        display: block;
        width: 200px;
        padding: 17px 0;
        text-align: center;
        color: #51568b;
        background-color: #fff;
        @extend .transition;
      }
      &:hover {
        .to__article {
          color: #fff;
          outline: 1px solid #fff;
          background-color: transparent;
        }
      }
    }
    .wp-pagenavi {
        @extend .flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 210px;
        padding-top: 70px;
        a {
          margin-right: 65px;
          width: 50px;
          height: 50px;
          font-size: 18px;
          @extend .transition;
          @extend .flex;
          align-items: center;
          justify-content: center;
          color: $base-font-color;
          &:hover {
            color: #fff;
            font-weight: 700;
            background-color: #4d4f8c;
          }
        }
        .current {
          margin-right: 65px;
          width: 50px;
          height: 50px;
          font-size: 18px;
          @extend .transition;
          color: #fff;
          font-weight: 700;
          background-color: #4d4f8c;
          @extend .flex;
          align-items: center;
          justify-content: center;
        }
        .previouspostslink {
          display: inline-block;
          width: 50px;
          height: 50px;
          border: 1px solid #4d4f8c;
          position: relative;
          margin-right: 80px;
          &:before {
            display: block;
            content:'';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background:transparent url('../img/back.svg') center no-repeat;
            background-size: 20px;
          }
        }
        .nextpostslink {
          display: inline-block;
          width: 50px;
          height: 50px;
          border: 1px solid #4d4f8c;
          position: relative;
          margin-left: 15px;
          &:before {
            display: block;
            content:'';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background:transparent url('../img/back.svg') center no-repeat;
            background-size: 20px;
            transform: rotate(180deg);
          }
        }
      }
  }

  //Page partners
  .partners__inner {
    padding: 125px 0 150px 0;
    .content__title {
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 25px;
    }
    .block__line {
      width: 140px;
      margin-bottom: 25px;
      &:before, &:after {
        width: 55px;
      }
    }
    .undertitile {
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 60px;
      font-family: $second-font-family;
      font-style: italic;
      line-height: 1.5;
      color: #4d4d4d; 
      font-size: 14px;
      text-align: center;
    }
    .map__inner {
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .map__point--wrap {
         position: absolute;
         @extend .transition;
         &:hover {
          .map__point {
            width: 65px;
            height: 65px;
            &:after {
              bottom: -7px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid #fff;
            }
            .point__inner {
              width: 40px;
              height: 40px;
              &:before {
                width: 26px;
                height: 26px;
                border: 3px solid #6cb7df;
                border-radius: 50%;
              }
              &:after {
                 border-left: 4px solid transparent;
                 border-right: 4px solid transparent;
                 border-top: 4px solid #fff;
                 bottom: -3px;
              }
            }
          }
          .point__logo {
              opacity: 1;
              visibility: visible;
            }
        }
         &.ternopol {
            top: 280px;
            left: 230px;
            &:hover {
              top: 245px;
              left: 210px;
            }
        }
        &.vinnitsya {
            top: 360px;
            left: 415px;
            &:hover {
              top: 320px;
              left: 395px;
            }
        }
        &.kiev {
            top: 260px;
            left: 500px;
            &:hover {
              top: 220px;
              left: 485px;
            }
        }
        &.nikolaev {
            bottom: 290px;
            left: 620px;
            &:hover {
              bottom: 330px;
              left: 600px;
            }
        }
        &.poltava {
            top: 300px;
            right: 400px;
            &:hover {
              right: 415px;
              top: 260px;
            }
        }
      }
      .map__point {
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 7px 12px 0 rgba(9, 31, 51, 0.27);
        @extend .flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @extend .transition;
        position: absolute;
        z-index: 10;
        &:after {
          display: block;
          content:'';
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: -3px;
          width: 0;
          height: 0;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-top: 3px solid #fff;
          @extend .transition;
          z-index: 2;
        }
        .point__inner {
          width: 16px;
          height: 16px;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 5px 12px 0 rgba(94, 153, 206, 0.5);
          @extend .flex;
          justify-content: center;
          align-items: center;
          position: relative;
          @extend .transition;
          &:before {
            display: block;
            content:'';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1.5px solid #51568b;
            @extend .transition;
          }
          &:after {
            display: block;
            content:'';
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            bottom: -2px;
            width: 0;
            height: 0;
            border-left: 2px solid transparent;
            border-right: 2px solid transparent;
            border-top: 2px solid #fff;
            @extend .transition;
          }
        }
      }
      .point__logo {
            position: absolute;
            left: -8px;
            top: -20px;
            width: 290px;
            height: 80px;
            background: #fff;
            padding: 20px 30px;
            @extend .transition;
            @extend .flex;
            align-items: center;
            justify-content: flex-end;
            opacity: 0;
            visibility: hidden;
            z-index: 0;
            box-shadow: 0 14px 16px 0 rgba(9, 31, 51, 0.6);
            img {
              width: auto;
              height: auto;
              max-width: 160px;
            }
        }
    }
  }

  //Page news
  .news__inner {
    padding: 120px 0 190px 0;
    .news__wrap {
      max-width: 1270px;
      margin: 0 auto;
      .news__item {
        margin-bottom: 100px;
        .thumbnail__wrap {
          width: 100%;
          max-height: 605px;
          margin-bottom: 60px;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
        .description__wrap {
          @extend .flex;
          align-items: flex-start;
          .left__author {
            text-align: center;
            color: #fff;
            width: 25%;
            position: relative;
            padding-bottom: 35px;
            margin-right: 70px;
            &:before {
              display: block;
              content:'';
              width: 100%;
              height: 170px;
              background-color: #6cb7df;
              box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
              position: absolute;
              left: 0;
              bottom: 0;
            }
            img {
              display: block;
              margin: 0 auto;
              margin-bottom: 30px;
              width: 135px;
              height: 135px;
              object-fit: cover;
              border-radius: 50%;
              border: 5px solid #fff;
              position: relative;
            }
            p {
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 10px;
              position: relative;
            }
            span {
              font-size: 14px;
              font-weight: 300;
              font-style: italic;
              position: relative;
            }
          }
          .right__description {
            width: 75%;
            .date {
              font-size: 14px;
              font-weight: 300;
              margin-bottom: 25px;
            }
            h4 {
              font-size: 36px;
              margin-bottom: 40px;
            }
            .undertitle {
              font-weight: 300;
              font-style: italic;
              line-height: 1.7;
              margin-bottom: 35px;
            }
            .excerpt {
              line-height: 1.7;
              margin-bottom: 50px;
            }
            .to__article {
              display: block;
              width: 200px;
              padding: 19px 0;
              color: #fff;
              text-align: center;
              background-color: #51568b;
              @extend .transition;
              &:hover {
                color: #51568b;
                background-color: #fff;
                outline: 1px solid #51568b;
              }
            }
          }
        }
      }
      .wp-pagenavi {
        @extend .flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 210px;
        padding-top: 70px;
        a {
          margin-right: 65px;
          width: 50px;
          height: 50px;
          font-size: 18px;
          @extend .transition;
          @extend .flex;
          align-items: center;
          justify-content: center;
          color: $base-font-color;
          &:hover {
            color: #fff;
            font-weight: 700;
            background-color: #4d4f8c;
            &:before {
              border-left-color: #85c3e5;
              border-bottom-color: #85c3e5;
            }
          }
        }
        .current {
          margin-right: 65px;
          width: 50px;
          height: 50px;
          font-size: 18px;
          @extend .transition;
          color: #fff;
          font-weight: 700;
          background-color: #4d4f8c;
          @extend .flex;
          align-items: center;
          justify-content: center;
        }
        .previouspostslink {
          display: inline-block;
          width: 50px;
          height: 50px;
          border: 1px solid #4d4f8c;
          position: relative;
          margin-right: 80px;
          &:before {
            display: block;
            content:'';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background:transparent url('../img/back.svg') center no-repeat;
            background-size: 20px;
          }
        }
        .nextpostslink {
          display: inline-block;
          width: 50px;
          height: 50px;
          border: 1px solid #4d4f8c;
          position: relative;
          margin-left: 15px;
          &:before {
            display: block;
            content:'';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background:transparent url('../img/back.svg') center no-repeat;
            background-size: 20px;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  //Single post
  .single__inner {
    padding: 120px 0 170px 0;
    .single__inner--wrap {
      max-width: 1275px;
      margin: 0 auto;
    }
    .author__block {
      text-align: center;
      color: #fff;
      width: 25%;
      position: relative;
      padding-bottom: 25px;
      margin: 0 auto;
      margin-bottom: 100px;
      &:before {
        display: block;
        content:'';
        width: 100%;
        height: 170px;
        background-color: #6cb7df;
        box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
        position: absolute;
        left: 0;
        bottom: 0;
      }
      img {
        display: block;
        margin: 0 auto;
        margin-bottom: 30px;
        width: 135px;
        height: 135px;
        object-fit: cover;
        border-radius: 50%;
        border: 5px solid #fff;
        position: relative;
      }
      p {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
        position: relative;
      }
      span {
        display: block;
        font-size: 14px;
        font-weight: 300;
        font-style: italic;
        position: relative;
        margin-bottom: 10px;
      }
      .date {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        font-style: italic;
        position: relative;
      }
    }
    .post__content {
      margin-bottom: 115px;
      .post__image {
        margin-bottom: 80px;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      h4 {
        font-size: 36px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 35px;
      }
      h6 {
        font-size: 24px;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 50px;
      }
      p {
        font-family: $second-font-family;
        font-size: 14px;
        line-height: 1.8;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 45px;
        color: #717171;
      }
      ul {
        li {
          font-family: $second-font-family;
          font-size: 14px;
          line-height: 1.8;
          margin-bottom: 25px;
          color: #717171;
          position: relative;
          @extend .flex;
          padding-left: 40px;
          &:before {
            display: block;
            content:'';
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #6cb7df;
            position: absolute;
            top: 10px;
            left: 0;
          }
        }
      }
      blockquote {
        max-width: 1070px;
        margin: 0 auto;
        margin-bottom: 70px;
        background: #4d4f8c url('../img/logo-p.svg') 120% top no-repeat;
        padding: 90px 230px 100px 255px;
        box-shadow: 0 20px 87px 0 rgba(77, 79, 140, 0.49);
        position: relative;
        &:before {
          display: block;
          content:'“';
          font-family: 'HelveticaNeueCyr';
          font-weight: 700;
          font-style: italic;
          font-size: 120px;
          color: #fff;
          position: absolute;
          top: 108px;
          left: 150px;
          opacity: .5;
          line-height: .6;
        }
        p {
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          font-style: italic;
          line-height: 1.7;
          margin-bottom: 0;
        }
      }
    }
    .prod__navigation--wrap {
      margin-top: 120px;
      margin-bottom: 170px;
      @extend .flex;
      a {
        background: #ededf3;
        background-size: 40px;
        padding: 50px 0;
        color: $base-font-color;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        @extend .transition;
        max-height: 120px;
        span {
          display: none;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 500;
          color: #fff;
        }
        &:hover {
          background-color: #6cb7df;
          box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
          color: #fff;
          transform: translateY(-8px);
          font-size: 16px;
          font-weight: 400;
          padding-top: 35px;
          span {
            display: block;
          }
        }
      }
      .prev {
        width: 50%;
        margin-right: 5px;
        padding-left: 130px;
        &:before {
          display: block;
          content:'';
          width: 0; 
          height: 0; 
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 10px solid #000;
          position: absolute;
          left: 100px;
          top: 52px;
          @extend .transition;
        }
        &:hover {
          &:before {
            border-right-color: #fff;
          }
        }
      }
      .next {
        background-size: 40px;
        width: 50%;
        padding-right: 130px;
        text-align: right;
        &:before {
          display: block;
          content:'';
          width: 0; 
          height: 0; 
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 10px solid #000;
          position: absolute;
          right: 100px;
          top: 52px;
          @extend .transition;
        }
        &:hover {
          &:before {
            border-left-color: #fff;
          }
        }
      }
    }
  }

  //Single problem page
  .single__problems--inner {
    padding: 125px 0 170px 0;
    .content__wrap {
      @extend .flex;
      max-width: 1270px;
      margin: 0 auto;
      margin-bottom: 100px;
      .left__image {
        width: 45%;
        margin-right: 100px;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      .right__content {
        width: 55%;
        h4 {
          font-size: 36px;
          margin-bottom: 40px;
        }
        .undertitle {
          font-family: $second-font-family;
          font-weight: 300;
          color: #717171;
        }
        p {
          margin-bottom: 45px;
          font-size: 14px;
          line-height: 1.7;
          span {
            font-weight: 700;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .prod__navigation--wrap {
    margin-top: 120px;
    margin-bottom: 170px;
    @extend .flex;
    a {
      background: #ededf3;
      background-size: 40px;
      padding: 50px 0;
      color: $base-font-color;
      font-size: 20px;
      font-weight: 500;
      position: relative;
      @extend .transition;
      max-height: 120px;
      span {
        display: none;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
      }
      &:hover {
        background-color: #6cb7df;
        box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
        color: #fff;
        transform: translateY(-8px);
        font-size: 16px;
        font-weight: 400;
        padding-top: 35px;
        span {
          display: block;
        }
      }
    }
    .prev {
      width: 50%;
      margin-right: 5px;
      padding-left: 130px;
      &:before {
        display: block;
        content:'';
        width: 0; 
        height: 0; 
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 10px solid #000;
        position: absolute;
        left: 100px;
        top: 52px;
        @extend .transition;
      }
      &:hover {
        &:before {
          border-right-color: #fff;
        }
      }
    }
    .next {
      background-size: 40px;
      width: 50%;
      padding-right: 130px;
      text-align: right;
      &:before {
        display: block;
        content:'';
        width: 0; 
        height: 0; 
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 10px solid #000;
        position: absolute;
        right: 100px;
        top: 52px;
        @extend .transition;
      }
      &:hover {
        &:before {
          border-left-color: #fff;
        }
      }
    }
  }
  }

  //Page resources
  .resources__inner {
    padding: 125px 0 170px 0;
    .content__title {
      font-size: 36px;
      font-weight: 400;
      margin-bottom: 25px;
      text-align: center;
    }
    .block__line {
      width: 140px;
      margin-bottom: 25px;
      &:before, &:after {
        width: 55px;
      }
    }
    .undertitle {
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 100px;
      font-family: $second-font-family;
      font-weight: 300;
      font-size: 14px;
      font-style: italic;
      color: #4d4d4d;
      line-height: 1.7;
      text-align: center;
    }
    .resources__tabs--wrap {
      @extend .flex;
      justify-content: space-between;
      margin-bottom: 100px;
      .res__tab {
        width: 32%;
        max-width: 490px;
        @extend .transition;
        position: relative;
        height: 400px;
        &:hover {
          transform: translateY(-8px);
          box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
          &:after {
            opacity: 1;
          }
          h4 {
            background-color: #6cb7df;
            color: #fff;
            font-weight: 700;
          }
        }
        &:after {
          display: block;
          content:'';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba(108, 183, 223, 0.7);
          z-index: 1;
           @extend .transition;
           opacity: 0;
        }
        h4 {
          color: #fff;
          background: #4d4f8c url('../img/logo-p.svg') 70% 0 no-repeat;
          text-align: center;
          font-size: 20px;
          padding: 38px 0;
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          @extend .transition;
          z-index: 5;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .resources__tab--item {
      .documentation__table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 4px;
      position: relative;
      tr {
        th {
          background-color: #51568b;
          position: relative;
          text-align: left;
          cursor: pointer;
          padding-left: 35px;
          font-size: 14px;
          color: #fff;
          a {
            display: block;
            font-size: 14px;
            color: #fff;
            padding: 23px 0 23px 0;
          }
          @extend .transition;
          &:hover {
            background-color: #6cb7df;
            box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
            transform: translateY(-8px);
            &:before {
              transform: rotate(180deg);
            }
            a {
              display: block;
              padding: 23px 0 23px 35px;
            }
          }
          &:before {
            display: block;
            content:'';
            width: 8px;
            height: 4px;
            background:transparent url('../img/arrow-up.png') center no-repeat;
            position: absolute;
            right: 25px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            @extend .transition;
          }
          &:first-child {
            width: 37.2%;
          }
          &:nth-child(2) {
            width: 10.4%;
          }
          &:nth-child(3) {
            width: 48.6%;
            &:before {
              background:transparent url('../img/download-icon.png') center no-repeat;
              width: 21px;
              height: 20px;
              top: 0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              right: 37px;
            }
            &:hover {
              &:before {
                transform: none;
              }
            }
          }
        }
        &:nth-child(2) {
          td {
            width: 100%;
            padding: 23px 0;
            font-size: 14px;
            font-weight: 500;
            background-color: #8e94c1;
            text-align: center;
            position: absolute;
            top: 68px;
            color: #fff;
            max-width: 1530px;
            width: 99.5%;
          }
        }
      }
    }
    .documentation__table--second {
      width: 100%;
      border-collapse: separate;
      border-spacing: 4px;
      position: relative;
      tr {
        @extend .transition;
        &:first-child {
          td {
            a {
              @extend .transition;
              opacity: 1;
            }
          }
        }
        td {
          font-family: $second-font-family;
          background-color: #fbfbfc;
          font-size: 14px;
          font-weight: 500;
          color: $base-font-color;
          padding: 23px 0 23px 35px;
          position: relative;
          text-align: left;
          cursor: pointer;
          @extend .transition;
          span {
            float: right;
            font-size: 14px;
            color: #fff;
            @extend .transition;
            opacity: 0;
          }
          &:first-child {
            width: 37.2%;
          }
          &:nth-child(2) {
            width: 10.4%;
            padding: 23px 0;
            text-align: center;
          }
          &:nth-child(3) {
            width: 48.6%;
            padding-right: 65px;
            position: relative;
            &:before {
              display: block;
              content:'';
              background:transparent url('../img/flie-icon-1.png') center no-repeat;
              width: 21px;
              height: 21px;
              top: 0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              right: 37px;
              position: absolute;
            }
          }
        }
        &:nth-child(even) {
          td {
            background-color: #f3f3f5;
          }
        }
        &:hover {
            td {
              background-color: #6cb7df;
              box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
              color: #fff;
              span {
                opacity: 1;
              }
              &:before {
                background:transparent url('../img/file-icon.png') center no-repeat;
              }
            }
          }
      }
    }
    .more__documents {
      display: block;
      width: 100%;
      padding: 21px 0;
      text-align: center;
      color: #fff;
      background-color: #6cb7df;
      box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
      font-size: 14px;
     }
     .left__image {
      width: 39%;
      margin-right: 80px;
      display: inline-block;
      vertical-align: top;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
     }
     .right__content {
      width: 50%;
      display: inline-block;
      padding-top: 80px;
      .top {
        margin-bottom: 20px;
      }
      h4 {
        font-size: 24px;
        margin-bottom: 40px;
      }
      .undertitle {
        text-align: left;
        margin-bottom: 35px;
        max-width: 100%;
      }
      p {
        display: block;
        line-height: 1.5;
        margin-bottom: 60px;
        span {
          font-weight: 600;
        }
      }
      .to__article {
        text-align: center;
        color: #fff;
        padding: 19px 0;
        background-color: #51568b;
        display: block;
        width: 200px;
        @extend .transition;
        &:hover {
          background-color: #6cb7df;
          box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
        }
      }
     }
    }
  }
  //Page FAQ
  .faq__inner {
    padding: 125px 0 170px;
    .content__title {
      font-size: 36px;
      font-weight: 400;
      margin-bottom: 25px;
      text-align: center;
    }
    .block__line {
      width: 140px;
      margin-bottom: 25px;
      &:before, &:after {
        width: 55px;
      }
    }
    .undertitle {
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 100px;
      font-family: $second-font-family;
      font-style: italic;
      line-height: 1.5;
      color: #4d4d4d; 
      font-size: 14px;
      text-align: center;
    }
  }
  //Questions accordeon
  .offenq__accordeon {
      max-width: 780px;
      margin: 0 auto;
      .accordeon__wrap {
        width: 100%;
        overflow: hidden;
        height: 65px;
        &.active {
          height: auto;
        }
      }
      .accordeon__top {
        width: 100%;
        background-color: #51568b;
        padding: 23px 0 23px 35px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 4px;
        cursor: pointer;
        @extend .transition;
        position: relative;
        z-index: 10;
        &:hover {
          background-color: #6cb7df;
          box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
        }
        span {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          position: absolute;
          right: 35px;
          top: 20px;
          @extend .flex;
          justify-content: center;
          padding-top: 5px;
          &:before {
            display: block;
            content:'';
            width: 0; 
            height: 0; 
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 8px solid #fff;
            position: absolute;
            @extend .transition;
          }
        }
        &.active {
          background-color: #6cb7df;
          box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
          span {
            padding-top: 8px;
            &:before {
              transform: rotate(90deg);
            }
          }
        }
      }
      .accordeon__content {
        border: 1px solid #f6f6f6;
        padding: 45px 35px;
        font-family: $second-font-family;
        font-size: 14px;
        @extend .transition;
        transform: translateY(-100%);
        margin-bottom: 10px;
        p {
          line-height: 1.7;
        }
        span {
          font-weight: 600;
        }
        &.active {
          transform: translateY(0);
        }
      }
     }
}

//Single inner
.single__page--inner {
  .inner__banner {
    h1 {
      font-size: 48px;
      padding-right: 50px;
      line-height: 1.1;
    }
  }
}

//Error page
.error404 {
  margin-top: 130px;
  .error__inner {
    height: calc(100vh - 130px);
    .page__wrap {
      height: calc(100vh - 130px);
      @extend .flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 300px;
        font-weight: 400;
        margin-bottom: 50px;
        line-height: .7;
        span {
          color: #8e94c1;
        }
      }
      .block__line {
        width: 140px;
        margin-bottom: 35px;
        &:before, &:after {
          width: 55px;
        }
      }
      p {
        font-family: $second-font-family;
        font-size: 30px;
        font-style: italic;
        margin-bottom: 55px;
        color: #4d4d4d;
      }
      a {
        display: block;
        width: 260px;
        padding: 19px 0;
        color: #fff;
        text-align: center;
        background-color: #51568b;
        @extend .transition;
        &:hover {
          color: #51568b;
          background-color: transparent;
          outline: 1px solid #51568b;
        }
      }
    }
  }
  footer {
    display: none;
  }
}

//Popups
.login__popup--wrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(77, 79, 140, .8);
  display: none;
  .login__popup--inner {
    @extend .flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .popup {
      @extend .flex;
      max-width: 680px;
      width: 100%;
      background-color: #fff;
      .left__image {
        width: 37%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right__form {
        width: 63%;
        padding: 35px 65px;
        text-align: center;
        position: relative;
        .close__popup {
          width: 17px;
          height: 17px;
          position: absolute;
          right: 25px;
          top: 25px;
          &:before {
            display: block;
            content:'';
            width: 2px;
            height: 17px;
            transform: rotate(-45deg);
            background-color: #8d92c0;
            position: absolute;
            right: 6px;
          }
          &:after {
            display: block;
            content:'';
            width: 2px;
            height: 17px;
            transform: rotate(45deg);
            background-color: #8d92c0;
            position: absolute;
            right: 6px;
          }
        }
        h4 {
          font-family: $second-font-family;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .block__line {
          width: 140px;
          margin-bottom: 25px;
          &:before, &:after {
            width: 55px;
          }
        }
        form {
          width: 100%;
          input {
            display: block;
            width: 100%;
            border: 1px solid #f9f9f9;
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 16px 0 16px 30px;
            font-family: $second-font-family;
            font-size: 14px;
            font-weight: 600;
            @extend .transition;
            &::-webkit-input-placeholder {
              font-family: $second-font-family;
              font-size: 14px;
              font-weight: 600;
              color: #000;
            }
            &::-moz-placeholder {
              font-family: $second-font-family;
              font-size: 14px;
              font-weight: 600;
              color: #000;
            }
            &:-ms-input-placeholder {
              font-family: $second-font-family;
              font-size: 14px;
              font-weight: 600;
              color: #000;
            }
            &:-moz-placeholder { 
              font-family: $second-font-family;
              font-size: 14px;
              font-weight: 600;
              color: #000;
            }
            &:focus {
              background: #6cb7df;
              background-size: 40px;
              box-shadow: 0 20px 87px 0 rgba(108, 183, 223, 0.5);
              color: #fff;
              outline: none;
            }
          }
        }
        .forgot__password {
          font-family: $second-font-family;
          display: block;
          padding-top: 15px;
          font-size: 12px;
          font-weight: 600;
          color: $base-font-color;
          margin-bottom: 40px;
          text-align: left;
        }
        .enter {
          display: block;
          width: 100%;
          padding: 19px 0;
          text-align: center;
          color: #fff;
          background-color: #51568b;
          margin-bottom: 30px;
          border: 0;
          @extend .transition;
          &:hover {
            background: #6cb7df;
            border-radius: 5px;
          }
        }
        .reg__link {
          font-family: $second-font-family;
          font-size: 10px;
          a {
            font-family: $second-font-family;
            font-size: 10px;
            font-weight: 700;
            color: $base-font-color;
          }
        }
      }
    }
    .register__popup {
      max-width: 750px;
      .left__image {
        width: 43%;
      }
      .right__form {
        width: 57%;
      }
    }
    .thankyou__popup {
      height: 420px;
      .right__form {
        @extend .flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
          display: block;
          font-size: 14px;
          font-style: italic;
          max-width: 220px;
          margin: 0 auto;
          line-height: 1.7;
          span {
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@import "fonts/font_roboto";
@import "fonts/font_opensans";