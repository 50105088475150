$rb-font-path: '../fonts' !default;

@font-face {
  font-family: 'OpenSans';
  font-weight: 300;
  font-style: italic;

  src: url('#{$rb-font-path}/OpenSansLight-Italic.eot');
  src: local('OpenSansLight-Italic'),
  local('OpenSansLight-Italic'),
  url('#{$rb-font-path}/OpenSansLight-Italic.eot') format('embedded-opentype'),
  url('#{$rb-font-path}/OpenSansLight-Italic.ttf') format('ttf'),
  url('#{$rb-font-path}/OpenSansLight-Italic.woff') format('woff'),
  url('#{$rb-font-path}/OpenSansLight-Italic.woff2') format('woff2'),
  url('#{$rb-font-path}/OpenSansLight-Italic.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 400;
  font-style: normal;

  src: url('#{$rb-font-path}/OpenSans-Regular.eot');
  src: local('OpenSans-Regular'),
  local('OpenSans-Regular'),
  url('#{$rb-font-path}/OpenSans-Regular.eot') format('embedded-opentype'),
  url('#{$rb-font-path}/OpenSans-Regular.ttf') format('ttf'),
  url('#{$rb-font-path}/OpenSans-Regular.woff') format('woff'),
  url('#{$rb-font-path}/OpenSans-Regular.woff2') format('woff2'),
  url('#{$rb-font-path}/OpenSans-Regular.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 400;
  font-style: italic;

  src: url('#{$rb-font-path}/OpenSans-Italic.eot');
  src: local('OpenSans-Italic'),
  local('OpenSans-Italic'),
  url('#{$rb-font-path}/OpenSans-Italic.eot') format('embedded-opentype'),
  url('#{$rb-font-path}/OpenSans-Italic.ttf') format('ttf'),
  url('#{$rb-font-path}/OpenSans-Italic.woff') format('woff'),
  url('#{$rb-font-path}/OpenSans-Italic.woff2') format('woff2'),
  url('#{$rb-font-path}/OpenSans-Italic.svg') format('svg');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  font-style: italic;

  src: url('#{$rb-font-path}/OpenSans-SemiBold.eot');
  src: local('OpenSans-SemiBold'),
  local('OpenSans-SemiBold'),
  url('#{$rb-font-path}/OpenSans-SemiBold.eot') format('embedded-opentype'),
  url('#{$rb-font-path}/OpenSans-SemiBold.ttf') format('ttf'),
  url('#{$rb-font-path}/OpenSans-SemiBold.woff') format('woff'),
  url('#{$rb-font-path}/OpenSans-SemiBold.woff2') format('woff2'),
  url('#{$rb-font-path}/OpenSans-SemiBold.svg') format('svg');
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  font-weight: 700;
  font-style: italic;

  src: url('#{$rb-font-path}/HelveticaNeue-BoldItalic.eot');
  src: local('HelveticaNeue-BoldItalic'),
  local('HelveticaNeue-BoldItalic'),
  url('#{$rb-font-path}/HelveticaNeue-BoldItalic.eot') format('embedded-opentype'),
  url('#{$rb-font-path}/HelveticaNeue-BoldItalic.ttf') format('ttf'),
  url('#{$rb-font-path}/HelveticaNeue-BoldItalic.woff') format('woff'),
  url('#{$rb-font-path}/HelveticaNeue-BoldItalic.woff2') format('woff2'),
  url('#{$rb-font-path}/HelveticaNeue-BoldItalic.svg') format('svg');
}
