// Font Sizes
$base-font-size: 16px;
$em-base: $base-font-size;
$base-font-family: 'Roboto', sans-serif;
$second-font-family: 'OpenSans', sans-serif;
$heading-font-family: $base-font-family;

// Line height
$base-line-height: 1.1;
$heading-line-height: 1.2;

// Other Sizes
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;

// Colors
$gray-medium: #999;
$gray-dark: #212121;
$gray-light: #ddd;

// Font Colors
$base-font-color: $gray-dark;

// Border
$base-border-color: $gray-light;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);